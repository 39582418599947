export default function () {
  const { locale } = this.$i18n
  return {
    shouldRender: !this.isVisitedArticle(this.$config.requestRefundArticleId),
    title: this.$t('trackingRefund.modal.title'),
    description: this.$t('requestRefund.modal.description'),
    onClose: this.closeModalAndResetNavigation,
    items: [
      {
        to: `https://refund.hotmart.com/tracking/?lang=${locale}`,
        target: '_blank',
        label: this.$t('trackingRefund.modal.tracking-refund'),
        dataLayer: {
          event_details: 'Click::Refund',
        },
      },
      {
        to: 'javascript:void(0)',
        onClick: this.closeModal,
        label: this.$t('trackingRefund.modal.problem'),
        dataLayer: {
          event_details: 'Click::OpenRequest',
        },
      },
    ],
    help_message: {
      label: this.$t('trackingRefund.modal.faq', {
        link: `${locale}/article/${this.$config.requestRefundArticleId}?utm_source=modal-ticket&utm_medium=track-refund`,
      }),
      onClick: () => {}, // This is a noop function
      dataLayer: {
        event_details: 'Click::FAQ',
      },
    },
    dataLayer: {
      custom_event_name: 'show_modal_track_refund',
      event_details: 'Open::ModalTrackRefund',
    },
  }
}
