import merge from 'lodash/merge'
import { mapGetters } from 'vuex'
import { countries, usRegions } from '~/enum/countries'
import { ITEM_LOCAL_STORAGE, REASONS_FORM, SPECIFICATION_TAG, SUBJECT_TAG } from '~/enum/form/constants'
import { TOOLS } from '~/enum/form/tools'
import { formOptions, languages, locations } from '~/enum/options'
import chatbot from '~/mixins/chatbot'
import user from '~/mixins/user'
import $Date from '~/utils/date'

export default {
  mixins: [user, chatbot],
  computed: {
    ...mapGetters('casUser', ['userToken']),
    shouldRenderSubmitButton() {
      if (!this.shouldRenderInSteps) return true

      if (this.currentStepIndex === 3 && this.shouldRenderAISummary) return false

      const targetStep = this.shouldRenderChangeOwnership ? 4 : 3
      return this.currentStepIndex === targetStep
    },
  },
  methods: {
    saveStorage(props) {
      const { fieldValue, reason } = props

      const saveLocalStorage = {
        country: fieldValue['country'],
        hotlink: fieldValue['hotlink'],
        howDidYouKnow: fieldValue['howDidYouKnow'],
        productId: fieldValue['productId'],
        language: fieldValue['language'],
        operationalSystem: fieldValue['operationalSystem'],
        reason: reason,
        specificationSelect: fieldValue?.specificationSelect,
        transactionCode: fieldValue['transactionCode'] || fieldValue['transactionCodeText'],
        certificateEmission: fieldValue['certificateEmission'],
      }

      const currentLocalStorage = JSON.parse(window.localStorage.getItem(ITEM_LOCAL_STORAGE)) || {}
      const newLocalStorage = JSON.stringify(merge(currentLocalStorage, saveLocalStorage))

      window.localStorage.setItem(ITEM_LOCAL_STORAGE, newLocalStorage)
    },
    showProducerAlertRefund(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.HELP_MY_STUDENTS].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER].includes(fieldValue?.specificationSelect)
      )
    },
    showAlertRefund(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.REFUND_OR_CANCELLATION].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.TRACK_REFUND].includes(fieldValue?.specificationSelect)
      )
    },
    renderAccessType(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.EMPLOYEE_CENTER].includes(fieldValue?.specificationSelect)
      )
    },
    renderRefundReason(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.REFUND_OR_CANCELLATION].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.REQUEST_REFUND].includes(fieldValue?.specificationSelect)
      )
    },
    renderProducerRefundReason(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.HELP_MY_STUDENTS].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER].includes(fieldValue?.specificationSelect)
      )
    },
    renderOtherTools(props) {
      const { fieldValue } = props

      return [
        SPECIFICATION_TAG.LINK_CHECKOUT_AFFILIATE,
        SPECIFICATION_TAG.LINK_CHECKOUT,
        SPECIFICATION_TAG.OTHER,
      ].includes(fieldValue?.specificationSelect)
    },
    showAlertPayments(props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.CANCELLATION, SPECIFICATION_TAG.SMART_INSTALLMENT_PAYMENT].includes(
        fieldValue?.specificationSelect
      )
    },
    showAlert(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.IMPORTING_STUDENTS, SPECIFICATION_TAG.SELL_PRODUCT_PACKAGES].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderCurrentDocument(props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS, SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY].includes(
        fieldValue?.specificationSelect
      )
    },
    renderNumberAccountCorrectionDocument(props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS].includes(fieldValue?.specificationSelect)
    },

    renderAccountUpdateDocument(props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY].includes(fieldValue?.specificationSelect)
    },
    renderProductEmail(props) {
      const { fieldValue } = props

      return [SUBJECT_TAG.PRODUCT_REVIEW].includes(fieldValue?.subjectSelect)
    },
    renderProducerOrAffiliate(props) {
      const { fieldValue } = props

      return [SUBJECT_TAG.BE_PRODUCER_OR_AFFILIATE].includes(fieldValue?.subjectSelect)
    },
    renderTransactionCode(props) {
      const { fieldValue, reason } = props

      return (
        [
          SUBJECT_TAG.REFUND_OR_CANCELLATION,
          SUBJECT_TAG.NOTE_EMISSION,
          SUBJECT_TAG.PRODUCT_QUESTIONS,
          SUBJECT_TAG.CERTIFICATE_EMISSION,
          SUBJECT_TAG.CANT_MAKE_PURCHASE,
          SUBJECT_TAG.SMART_SUBSCRIPTIONS_PAYMENTS,
          SUBJECT_TAG.UNSUBSCRIBE,
        ].includes(fieldValue?.subjectSelect) ||
        ([REASONS_FORM.AFFILIATE_FORM, REASONS_FORM.BUYER_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(
          reason
        ) &&
          [
            SPECIFICATION_TAG.CHANGE_COMMISSION_BETWEEN_AFFILIATES,
            SPECIFICATION_TAG.CANT_ACCESS_ACCOUNT,
            SPECIFICATION_TAG.TROUBLE_ACESSING_CONTENT,
            SPECIFICATION_TAG.KNOW_ACCESS_MYCOURSE,
            SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
            SPECIFICATION_TAG.TRANSFER_OWNERSHIP,
            SPECIFICATION_TAG.CHECKIN,
            SPECIFICATION_TAG.CHANGE_REQUEST_REASON,
            SPECIFICATION_TAG.MY_PRODUCT_CONTENT_QUESTIONS,
            SPECIFICATION_TAG.ACCESS_DEADLINES_QUESTIONS,
            SPECIFICATION_TAG.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS,
            SPECIFICATION_TAG.SOCIAL_MEDIA_GROUPS_QUESTIONS,
            SPECIFICATION_TAG.BILLET_BANKING,
            SPECIFICATION_TAG.CREDIT_CARD,
            SPECIFICATION_TAG.PIX,
            SPECIFICATION_TAG.SMART_SUBSCRIPTIONS_PAYMENTS,
            SPECIFICATION_TAG.PAYMENT_INFORMATION,
            SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
            SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
          ].includes(fieldValue?.specificationSelect))
      )
    },
    renderTransactionCodePhysicalProducts(props) {
      const { fieldValue, reason } = props

      return (
        [SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS].includes(fieldValue?.subjectSelect) ||
        ([REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_TRACK_MY_ORDER,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER,
          ].includes(fieldValue?.specificationSelect))
      )
    },
    renderSubscriptionCode(props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
          SPECIFICATION_TAG.CANCEL_SUBSCRIPTION,
          SPECIFICATION_TAG.CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderLinkCheckout(props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.NOT_FOUND_PAYMENT_METHOD].includes(fieldValue?.specificationSelect)
      )
    },
    renderEmancipateMinor(props) {
      const { fieldValue, reason } = props

      return [SPECIFICATION_TAG.AFFILIATE_PRODUCER_EMANCIPATE_MINOR].includes(fieldValue?.specificationSelect)
    },
    renderPurchaseDate(props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.BUYER_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        ([
          SPECIFICATION_TAG.PIX,
          SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
          SPECIFICATION_TAG.TRACK_REFUND,
          SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
          SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
        ].includes(fieldValue?.specificationSelect) ||
          [SUBJECT_TAG.REFUND_OR_CANCELLATION].includes(fieldValue?.subjectSelect))
      )
    },
    isTransactionCodeRequired(props) {
      const { fieldValue, reason } = props

      return !(
        [SUBJECT_TAG.NOTE_EMISSION, SUBJECT_TAG.PRODUCT_QUESTIONS, SUBJECT_TAG.CERTIFICATE_EMISSION].includes(
          fieldValue?.subjectSelect
        ) ||
        ([REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.CANT_ACCESS_ACCOUNT,
            SPECIFICATION_TAG.TROUBLE_ACESSING_CONTENT,
            SPECIFICATION_TAG.KNOW_ACCESS_MYCOURSE,
            SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
            SPECIFICATION_TAG.TRANSFER_OWNERSHIP,
            SPECIFICATION_TAG.CHECKIN,
            SPECIFICATION_TAG.CHANGE_REQUEST_REASON,
            SPECIFICATION_TAG.MY_PRODUCT_CONTENT_QUESTIONS,
            SPECIFICATION_TAG.ACCESS_DEADLINES_QUESTIONS,
            SPECIFICATION_TAG.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS,
            SPECIFICATION_TAG.SOCIAL_MEDIA_GROUPS_QUESTIONS,
          ].includes(fieldValue?.specificationSelect))
      )
    },
    isTransactionCodePhysicalProductsRequired(props) {
      const { fieldValue, reason } = props

      return (
        [SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS].includes(fieldValue?.subjectSelect) ||
        ([REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_TRACK_MY_ORDER,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER,
          ].includes(fieldValue?.specificationSelect))
      )
    },
    isProductNameRequired(props) {
      const { fieldValue, reason } = props

      return !(
        [SUBJECT_TAG.BUSINESS_STRATEGIES_PRODUCER, SUBJECT_TAG.REFUND_OR_CANCELLATION].includes(
          fieldValue?.subjectSelect
        ) ||
        [
          SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
          SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
          SPECIFICATION_TAG.TRACK_REFUND,
          SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
          SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderHotlink: function (props) {
      const { fieldValue, reason } = props

      return (
        ([REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
          [
            SUBJECT_TAG.HOTLINK,
            SUBJECT_TAG.WITHDRAWALS_AND_COMMISSIONS_AFFILIATE,
            SUBJECT_TAG.WITHDRAWALS_AND_COMMISSIONS_PRODUCER,
          ].includes(fieldValue?.subjectSelect)) ||
        [SPECIFICATION_TAG.DISCLOSURE_LINKS, SPECIFICATION_TAG.HOTLEADS_AFFILIATE].includes(
          fieldValue?.specificationSelect
        ) ||
        (fieldValue?.tool === this.$t('ticket.subject.specifications.options.parameters') &&
          [SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE, SPECIFICATION_TAG.TRACKING_PIXEL].includes(
            fieldValue?.specificationSelect
          ))
      )
    },
    isHotLinkRequired(props) {
      const { fieldValue, reason } = props

      return [REASONS_FORM.AFFILIATE_FORM].includes(reason) && [SUBJECT_TAG.HOTLINK].includes(fieldValue?.subjectSelect)
    },
    isRenderStudentEmail(props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.SMART_SUBSCRIPTIONS_PAYMENTS, SPECIFICATION_TAG.CANT_MAKE_PURCHASE].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderShortLink: function (props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.HOTLINK].includes(fieldValue?.specificationSelect)
      )
    },
    renderMaterialLink: function (props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.PROMOTIONAL_MATERIAL].includes(fieldValue?.specificationSelect)
      )
    },
    renderAutomatedDecision: function (props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.DATA_PRIVACY_FORM].includes(reason) &&
        [SUBJECT_TAG.AUTOMATED_DECISION_REVIEW].includes(fieldValue?.subjectSelect)
      )
    },
    renderDetailNotificationReceived: function (props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.AFFILIATE_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER_AFFILIATE, SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER].includes(
          fieldValue?.subjectSelect
        ) &&
        [
          SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT,
          SPECIFICATION_TAG.AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION,
          SPECIFICATION_TAG.AFFILIATE_PRODUCER_EMANCIPATE_MINOR,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderOperationalSystem: function (props) {
      const { reason } = props

      return [REASONS_FORM.SPARKLE_FORM].includes(reason)
    },
    renderHowDidYouKnow: function (props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.NEW_USER_FORM].includes(reason) &&
        [
          SUBJECT_TAG.CREATE_A_PRODUCT_AT_HOTMART,
          SUBJECT_TAG.PROMOTE_A_PRODUCT_AT_HOTMART,
          SUBJECT_TAG.KNOW_MORE_ABOUT_HOTMART,
        ].includes(fieldValue?.subjectSelect)
      )
    },
    renderSalesPageLink: function (props) {
      const { reason, fieldValue } = props

      return (
        ([REASONS_FORM.NEW_USER_FORM].includes(reason) &&
          [
            SUBJECT_TAG.CREATE_A_PRODUCT_AT_HOTMART,
            SUBJECT_TAG.PROMOTE_A_PRODUCT_AT_HOTMART,
            SUBJECT_TAG.KNOW_MORE_ABOUT_HOTMART,
          ].includes(fieldValue?.subjectSelect)) ||
        [
          SPECIFICATION_TAG.SALES_PAGE,
          SPECIFICATION_TAG.TRADEMARK_VIOLATION,
          SPECIFICATION_TAG.LEGAL_PLAGIARISM,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderProductLink: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.NEW_USER_FORM].includes(reason) &&
        [SUBJECT_TAG.BUY_A_PRODUCT_AT_HOTMART].includes(fieldValue?.subjectSelect)
      )
    },
    renderIfHotmartAnalyticsTool: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.TRACKING_PIXEL].includes(fieldValue?.specificationSelect) &&
        [this.$t('ticket.subject.specifications.options.hotmartAnalytics')].includes(fieldValue?.tool)
      )
    },
    isSalesPageLinkRequired(props) {
      const { fieldValue, reason } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL].includes(fieldValue?.subjectSelect)
      )
    },
    renderSourceProductIdAndImportReason: function (props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.IMPORTING_STUDENTS].includes(fieldValue?.specificationSelect) &&
        fieldValue?.tool === this.$t('ticket.subject.specifications.options.importingStudentsTool')
      )
    },
    renderIDProduct: function (props) {
      const { reason, fieldValue } = props

      return (
        [
          REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM,
          REASONS_FORM.NEW_USER_FORM,
          REASONS_FORM.AFFILIATE_FORM,
          REASONS_FORM.BUYER_FORM,
        ].includes(reason) &&
        ([
          SUBJECT_TAG.PRODUCT_REVIEW,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER,
          SUBJECT_TAG.CREATE_A_PRODUCT_AT_HOTMART,
          SUBJECT_TAG.PROMOTE_A_PRODUCT_AT_HOTMART,
          SUBJECT_TAG.KNOW_MORE_ABOUT_HOTMART,
          SUBJECT_TAG.BUSINESS_STRATEGIES_PRODUCER,
          SUBJECT_TAG.HOTLINK,
          SUBJECT_TAG.AFFILIATE_PROGRAM_HELP,
          SUBJECT_TAG.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
          SUBJECT_TAG.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
          SUBJECT_TAG.REPORTS,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_AFFILIATE,
          SUBJECT_TAG.TOOLS_AFFILIATE,
          SUBJECT_TAG.REPORTS_PRODUCER,
          SUBJECT_TAG.TOOLS_PRODUCER,
        ].includes(fieldValue?.subjectSelect) ||
          [
            SPECIFICATION_TAG.SALES_STRATEGIES,
            SPECIFICATION_TAG.PRODUCER_CONTACT,
            SPECIFICATION_TAG.CHECKOUT,
            SPECIFICATION_TAG.CHECKOUT_AFFILIATE,
            SPECIFICATION_TAG.TRACKING_PIXEL,
            SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE,
            SPECIFICATION_TAG.REPORTS_AND_CLICKS,
            SPECIFICATION_TAG.HOTLEADS,
            SPECIFICATION_TAG.HOTLEADS_AFFILIATE,
            SPECIFICATION_TAG.LINK_SHORTENER,
            SPECIFICATION_TAG.BONUS_CONTENT,
            SPECIFICATION_TAG.CART_ABANDONMENT,
            SPECIFICATION_TAG.MEMBERS_AREA,
            SPECIFICATION_TAG.STUDENT_REVIEWS,
            SPECIFICATION_TAG.HOTLINK,
            SPECIFICATION_TAG.LISTBOSS,
            SPECIFICATION_TAG.PROMOTIONAL_MATERIAL,
            SPECIFICATION_TAG.HOTMART_ACCOUNT_ACCESS,
            SPECIFICATION_TAG.CHANGE_IN_REGISTRATION_INFORMATION,
            SPECIFICATION_TAG.REFUND_OR_CANCELLATION,
            SPECIFICATION_TAG.CONTENT_OR_CERTIFICATE,
            SPECIFICATION_TAG.PAYMENT_INFORMATION,
          ].includes(fieldValue?.specificationSelect)) &&
        fieldValue?.specificationSelect !== SPECIFICATION_TAG.SELL_PRODUCT_PACKAGES
      )
    },
    renderIDSProduct: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.SELL_PRODUCT_PACKAGES].includes(fieldValue?.specificationSelect)
      )
    },
    renderIDProductHelpMessage: function (props) {
      const { reason, fieldValue } = props

      if (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.IMPORTING_STUDENTS, SPECIFICATION_TAG.CHANGE_EVENT_DATE].includes(
          fieldValue?.specificationSelect
        )
      ) {
        return true
      }

      return (
        [REASONS_FORM.AFFILIATE_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        ([
          SUBJECT_TAG.REPORTS,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_AFFILIATE,
          SUBJECT_TAG.TOOLS_AFFILIATE,
          SUBJECT_TAG.REPORTS_PRODUCER,
        ].includes(fieldValue?.subjectSelect) ||
          [
            SPECIFICATION_TAG.HOTLEADS,
            SPECIFICATION_TAG.HOTLEADS_AFFILIATE,
            SPECIFICATION_TAG.LINK_SHORTENER,
            SPECIFICATION_TAG.BONUS_CONTENT,
            SPECIFICATION_TAG.CONTENT_DELIVERY,
            SPECIFICATION_TAG.CREDENTIALS,
            SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA,
            SPECIFICATION_TAG.SALES_PAGE,
            SPECIFICATION_TAG.INTEGRATIONS,
            SPECIFICATION_TAG.EMPLOYEE_CENTER,
            SPECIFICATION_TAG.CART_ABANDONMENT,
            SPECIFICATION_TAG.PROTECT_CONTENT,
            SPECIFICATION_TAG.MEMBERS_AREA,
            SPECIFICATION_TAG.CHANGE_COURSE_FORMAT,
            SPECIFICATION_TAG.STUDENT_REVIEWS,
            SPECIFICATION_TAG.HOTLINK,
            SPECIFICATION_TAG.LISTBOSS,
            SPECIFICATION_TAG.PROMOTIONAL_MATERIAL,
            SPECIFICATION_TAG.TRACKING_PIXEL,
            SPECIFICATION_TAG.PAYMENT_INFORMATION,
          ].includes(fieldValue?.specificationSelect))
      )
    },
    renderIDCommunity: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.CONTENT_DELIVERY].includes(fieldValue?.specificationSelect)
      )
    },
    renderAcquisitionUrl: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.CREDENTIALS].includes(fieldValue?.specificationSelect)
      )
    },
    renderPixelEmailAndID: function (props) {
      const { fieldValue } = props

      return [this.$t('ticket.subject.specifications.options.trackingPixelTool')].includes(fieldValue?.tool)
    },
    renderEmailProvider: function (props) {
      const { fieldValue, reason } = props

      return (
        [SUBJECT_TAG.TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.LISTBOSS, SPECIFICATION_TAG.HOTLEADS].includes(fieldValue?.specificationSelect)
      )
    },
    renderStudentEmail: function (props) {
      const { fieldValue, reason } = props

      return (
        ([SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
          [SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA].includes(fieldValue?.specificationSelect)) ||
        [
          SPECIFICATION_TAG.SMART_SUBSCRIPTIONS_PAYMENTS,
          SPECIFICATION_TAG.PAYMENT_INFORMATION,
          SPECIFICATION_TAG.CANT_MAKE_PURCHASE,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderIntegrationEmail: function (props) {
      const { fieldValue, reason } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.INTEGRATIONS].includes(fieldValue?.specificationSelect)
      )
    },
    renderEventDate: function (props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.CHANGE_EVENT_DATE].includes(fieldValue?.specificationSelect)
      )
    },
    isIDProductRequired: function (props) {
      const { fieldValue } = props

      if (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.CHANGE_EVENT_DATE].includes(fieldValue?.specificationSelect)
      ) {
        return false
      }

      return (
        [
          SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER,
          SUBJECT_TAG.BUSINESS_STRATEGIES_PRODUCER,
          SUBJECT_TAG.AFFILIATE_PROGRAM_HELP,
          SUBJECT_TAG.BUSINESS_STRATEGIES_PRODUCER,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_AFFILIATE,
          SUBJECT_TAG.TOOLS_AFFILIATE,
          SUBJECT_TAG.REPORTS_PRODUCER,
          SUBJECT_TAG.TOOLS_PRODUCER,
        ].includes(fieldValue?.subjectSelect) ||
        [
          SPECIFICATION_TAG.AFFILIATE_PROGRAM,
          SPECIFICATION_TAG.COPRODUCER,
          SPECIFICATION_TAG.MARKETPLACE,
          SPECIFICATION_TAG.HOTMART_ONE,
          SPECIFICATION_TAG.AFFILIATE_PROGRAM_PRODUCER,
          SPECIFICATION_TAG.DOUBTS,
          SPECIFICATION_TAG.UNRELEASED_PRODUCT,
          SPECIFICATION_TAG.HELP_REGISTER_PRODUCT,
          SPECIFICATION_TAG.BLUEPRINT_ANALYSIS,
          SPECIFICATION_TAG.PRODUCT_REVIEW_LAUNCH,
          SPECIFICATION_TAG.LEAD_COUNT,
          SPECIFICATION_TAG.LEAD_DATA,
          SPECIFICATION_TAG.HOTLEADS,
          SPECIFICATION_TAG.HOTLEADS_AFFILIATE,
          SPECIFICATION_TAG.BONUS_CONTENT,
          SPECIFICATION_TAG.CART_ABANDONMENT,
          SPECIFICATION_TAG.STUDENT_REVIEWS,
          SPECIFICATION_TAG.MEMBERS_AREA,
          SPECIFICATION_TAG.LISTBOSS,
          SPECIFICATION_TAG.PROMOTIONAL_MATERIAL,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderBonusContent: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.TOOLS_AFFILIATE].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.BONUS_CONTENT].includes(fieldValue?.specificationSelect)
      )
    },

    renderBonusContentMessage: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.TOOLS_AFFILIATE].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.BONUS_CONTENT].includes(fieldValue?.specificationSelect)
      )
    },

    renderShortenLink: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.TOOLS_AFFILIATE].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.LINK_SHORTENER].includes(fieldValue?.specificationSelect)
      )
    },
    renderCountry: function (props) {
      const { reason } = props

      return ![REASONS_FORM.DATA_PRIVACY_FORM].includes(reason)
    },
    renderCountryPrivacy: function (props) {
      const { reason } = props

      return [REASONS_FORM.DATA_PRIVACY_FORM].includes(reason)
    },
    renderRegion: function (props) {
      const { fieldValue } = props

      return ['country_unitedstates_privacy'].includes(fieldValue['countryPrivacy'])
    },
    renderCountryDestination: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SPECIFICATION_TAG.IMAGE_VIOLATION, SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderProductName: function (props) {
      const { reason, fieldValue } = props

      return (
        ([REASONS_FORM.BUYER_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.CANT_ACCESS_ACCOUNT,
            SPECIFICATION_TAG.TROUBLE_ACESSING_CONTENT,
            SPECIFICATION_TAG.KNOW_ACCESS_MYCOURSE,
            SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
            SPECIFICATION_TAG.TRANSFER_OWNERSHIP,
            SPECIFICATION_TAG.CHECKIN,
            SPECIFICATION_TAG.CHANGE_REQUEST_REASON,
            SPECIFICATION_TAG.MY_PRODUCT_CONTENT_QUESTIONS,
            SPECIFICATION_TAG.ACCESS_DEADLINES_QUESTIONS,
            SPECIFICATION_TAG.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS,
            SPECIFICATION_TAG.SOCIAL_MEDIA_GROUPS_QUESTIONS,
            SPECIFICATION_TAG.BILLET_BANKING,
            SPECIFICATION_TAG.CREDIT_CARD,
            SPECIFICATION_TAG.OTHER_PAYMENT_METHOD,
            SPECIFICATION_TAG.PIX,
            SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
            SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
            SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
            SPECIFICATION_TAG.TRACK_REFUND,
            SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
            SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
          ].includes(fieldValue?.specificationSelect)) ||
        [
          SUBJECT_TAG.NOTE_EMISSION,
          SUBJECT_TAG.PRODUCT_QUESTIONS,
          SUBJECT_TAG.CERTIFICATE_EMISSION,
          SUBJECT_TAG.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
          SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL,
          SUBJECT_TAG.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
          SUBJECT_TAG.CANT_MAKE_PURCHASE,
          SUBJECT_TAG.SMART_SUBSCRIPTIONS_PAYMENTS,
          SUBJECT_TAG.BUY_A_PRODUCT_AT_HOTMART,
          SUBJECT_TAG.REFUND_OR_CANCELLATION,
          SUBJECT_TAG.UNSUBSCRIBE,
          SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS,
        ].includes(fieldValue?.subjectSelect)
      )
    },
    renderPaymentMethod: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.OTHER_PAYMENT_METHOD].includes(fieldValue?.specificationSelect)
      )
    },
    renderPurchaseEmailCheckbox: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.NOT_RECEIVEING_EMAILS, SPECIFICATION_TAG.RA_NOT_RECEIVEING_EMAILS].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderPurchaseEmail: function (props) {
      const { reason, fieldValue } = props

      const checkBoxActive = fieldValue['purchaseEmailSameAbove'] ?? true

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.NOT_RECEIVEING_EMAILS, SPECIFICATION_TAG.RA_NOT_RECEIVEING_EMAILS].includes(
          fieldValue?.specificationSelect
        ) &&
        !checkBoxActive
      )
    },
    renderUpdateEmail: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.CHANGING_HOTMART_EMAIL, SPECIFICATION_TAG.CHANGE_REQUEST_REASON].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderChangeRequestReason: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.CHANGE_REQUEST_REASON].includes(fieldValue?.specificationSelect)
      )
    },
    showLabelAttachment: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM, REASONS_FORM.AFFILIATE_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(
          reason
        ) &&
        ![SPECIFICATION_TAG.CHANGING_HOTMART_EMAIL, SPECIFICATION_TAG.OTHER_CHANGES].includes(
          fieldValue?.specificationSelect
        )
      )
    },
    renderDevice: function (props) {
      const { reason, fieldValue } = props

      return (
        ([REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.TROUBLE_ACESSING_CONTENT,
            SPECIFICATION_TAG.ERROR_MESSAGE_APPEARED,
            SPECIFICATION_TAG.ERROR_MESSAGE,
            SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
          ].includes(fieldValue?.specificationSelect)) ||
        [SUBJECT_TAG.CERTIFICATE_EMISSION].includes(fieldValue?.subjectSelect)
      )
    },
    renderEventTicketCode: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.TRANSFER_OWNERSHIP, SPECIFICATION_TAG.CHECKIN].includes(fieldValue?.specificationSelect)
      )
    },
    renderCoProducerEmail: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.COPRODUCER].includes(fieldValue?.specificationSelect)
      )
    },

    renderLeadPageLink: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.HOTLEADS].includes(fieldValue?.specificationSelect)
      )
    },

    renderLegalFields: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL].includes(fieldValue?.subjectSelect)
      )
    },

    renderLegalPlagiarism: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.LEGAL_PLAGIARISM].includes(fieldValue?.specificationSelect)
      )
    },

    renderLegalImage: function (props) {
      const { reason, fieldValue, shouldRenderChangeOwnership } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        ([
          SPECIFICATION_TAG.IMAGE_VIOLATION,
          SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS,
          SPECIFICATION_TAG.CHANGE_REGISTRATION,
          SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF,
          !shouldRenderChangeOwnership && SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
        ].includes(fieldValue?.specificationSelect) ||
          [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER, SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE].includes(
            fieldValue?.subjectSelect
          ))
      )
    },

    renderSelfieDocumentRG: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SPECIFICATION_TAG.AFFILIATE_PRODUCER_EMANCIPATE_MINOR].includes(fieldValue?.specificationSelect)
      )
    },

    renderFileOfficialDocument: function (props) {
      const { reason, fieldValue } = props

      return (
        ([REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
          [SPECIFICATION_TAG.IMAGE_VIOLATION, SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS].includes(
            fieldValue?.specificationSelect
          )) ||
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER, SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE].includes(
          fieldValue.subjectSelect
        )
      )
    },

    renderFileCopyright: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.LEGAL_PLAGIARISM, SPECIFICATION_TAG.TRADEMARK_VIOLATION].includes(
          fieldValue?.specificationSelect
        )
      )
    },

    renderFileNewDocumentCPF: function (props) {
      const { reason, fieldValue } = props

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [
          SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE,
          SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER,
        ].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF].includes(fieldValue?.specificationSelect)
      )
    },
    renderHelpTextViolationOfTerms: function (props) {
      const { fieldValue } = props

      return [SUBJECT_TAG.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE].includes(fieldValue?.subjectSelect)
    },
    renderAwards: function (props) {
      const { fieldValue, reason } = props
      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER, SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE].includes(
          fieldValue?.subjectSelect
        )
      )
    },
    renderPhoneNumber: function (props) {
      const { fieldValue, reason } = props
      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER, SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE].includes(
          fieldValue?.subjectSelect
        )
      )
    },
    renderFullAddress: function (props) {
      const { fieldValue, reason } = props
      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER, SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE].includes(
          fieldValue?.subjectSelect
        )
      )
    },
    renderSelectTool: function (props) {
      const { fieldValue } = props

      return (
        [
          SUBJECT_TAG.TOOLS_AFFILIATE,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER,
          SUBJECT_TAG.SETTINGS_AND_TOOLS_AFFILIATE,
          SUBJECT_TAG.TOOLS_PRODUCER,
        ].includes(fieldValue?.subjectSelect) &&
        [
          SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE,
          SPECIFICATION_TAG.CONTENT_DELIVERY,
          SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA,
          SPECIFICATION_TAG.SALES_PAGE,
          SPECIFICATION_TAG.DISCLOSURE_LINKS,
          SPECIFICATION_TAG.EXTERNAL_PLATFORMS,
          SPECIFICATION_TAG.INTEGRATIONS,
          SPECIFICATION_TAG.EMPLOYEE_CENTER,
          SPECIFICATION_TAG.PROTECT_CONTENT,
          SPECIFICATION_TAG.IMPORTING_STUDENTS,
          SPECIFICATION_TAG.TRACKING_PIXEL,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderToolsLead: function (props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.REPORTS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.PRODUCER_LEADS].includes(fieldValue?.specificationSelect)
      )
    },
    renderSelectCredential: function (props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        SPECIFICATION_TAG.CREDENTIALS === fieldValue?.specificationSelect
      )
    },
    renderSelectBonusContent: function (props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.MEMBERS_AREA].includes(fieldValue?.specificationSelect)
      )
    },
    renderTestPurchase: function (props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.DISCLOSURE_LINKS].includes(fieldValue?.specificationSelect)
    },
    renderChatBot: function (props) {
      const { reason, fieldValue } = props
      if (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SUBJECT_TAG.REFUND_OR_CANCELLATION,
          SUBJECT_TAG.PAYMENT_INFORMATION,
          SUBJECT_TAG.CHANGE_IN_REGISTRATION_INFORMATION,
          SUBJECT_TAG.HOTMART_COURSE_ACCESS,
        ].includes(fieldValue?.subjectSelect) &&
        [
          SPECIFICATION_TAG.CANCEL_SUBSCRIPTION,
          SPECIFICATION_TAG.INFO_ABOUT_SUBSCRIPTION_PAYMENT,
          SPECIFICATION_TAG.CHANGE_REQUEST_REASON,
          SPECIFICATION_TAG.CANT_ACCESS_ACCOUNT,
          SPECIFICATION_TAG.KNOW_ACCESS_MYCOURSE,
          SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
        ].includes(fieldValue?.specificationSelect) &&
        /^(en|es)$/i.test(this.$route.params.locale)
      ) {
        this.toggleChat()
      }
    },
    getUpdateEmailKey: function (props) {
      const { reason, fieldValue } = props ?? {}

      if (SPECIFICATION_TAG.CHANGING_HOTMART_EMAIL === fieldValue?.specificationSelect) {
        this.fieldValue['updateEmailToAllPurchases'] = true
        this.fieldValue['updateEmailInMyHotmartRegistration'] = false

        return 'updateEmailToAllPurchases'
      }

      if (SPECIFICATION_TAG.CHANGE_REQUEST_REASON === fieldValue?.specificationSelect) {
        this.fieldValue['updateEmailInMyHotmartRegistration'] = true
        this.fieldValue['updateEmailToAllPurchases'] = false

        return 'updateEmailInMyHotmartRegistration'
      }
    },
    toExtractReason() {
      const data = formOptions.find(({ reason }) => reason === this.reason)

      return data
    },
    getAlertMessage(props) {
      const { reason, fieldValue } = props ?? {}

      const labels = {
        [SPECIFICATION_TAG.IMPORTING_STUDENTS]: 'importingStudents',
        [SPECIFICATION_TAG.SELL_PRODUCT_PACKAGES]: 'sellProductPackages',
      }

      return labels[fieldValue?.specificationSelect]
    },
    getAllCountries() {
      return countries.map(country => ({ id: country.id, text: this.$t(country.i18nKey) }))
    },
    getRegions() {
      return usRegions.map(region => ({ id: region.id, text: region.value }))
    },
    getLocations() {
      return locations.map(location => ({ id: location, text: this.$t(`locations.${location}`) }))
    },
    getLanguages() {
      const unsupportedLanguages = ['arabe', 'japones']

      return languages
        .filter(lang => !unsupportedLanguages.includes(lang))
        .map(language => ({ id: language, text: this.$t(`languages.${language}`) }))
    },
    getAwards() {
      const awards = ['amulet', 'frame', 'blackBox']

      return awards.map(award => ({ text: this.$t(`ticket.message.fields.awards.${award}`) }))
    },
    getDevices() {
      const devices = {
        desktop: 'device_computer',
        cell: 'device_phone',
        tablet: 'device_tablet',
        sparkle: 'device_hotmart_sparkle',
        tv: 'device_hotmart_tv',
      }

      return Object.entries(devices).map(([device, value]) => ({
        id: value,
        text: this.$t(`ticket.complementaryData.fields.howToAcessAndWatching.deviceOptions.${device}`),
      }))
    },
    isRequiredEventDate(dateName) {
      return ['startOriginalDate', 'startNewDate', 'endNewDate'].includes(dateName)
    },
    disableEventDate({ fieldValue }, dateName) {
      return {
        endOriginalDate: !fieldValue['startOriginalDate'],
        startNewDate: !fieldValue['startOriginalDate'],
        endNewDate: !fieldValue['startNewDate'],
      }[dateName]
    },
    validateEventDate({ fieldValue: { startOriginalDate, endOriginalDate, startNewDate } }, dateName) {
      return date => {
        return {
          startOriginalDate:
            new $Date(date).isBefore(new $Date().startOf('day')) &&
            'ticket.complementaryData.fields.startOriginalDate.error',
          endOriginalDate:
            new $Date(date).isBefore(new $Date(startOriginalDate)) &&
            'ticket.complementaryData.fields.endOriginalDate.error',
          startNewDate: (() => {
            if (new $Date(date).isBefore(new $Date(startOriginalDate).add(72, 'hours'))) {
              return 'ticket.complementaryData.fields.startNewDate.error1'
            }
            if (new $Date(date).isAfter(new $Date(startOriginalDate).add(1, 'year'))) {
              return 'ticket.complementaryData.fields.startNewDate.error2'
            }

            return false
          })(),
          endNewDate:
            new $Date(date).isBefore(new $Date(startNewDate)) && 'ticket.complementaryData.fields.endNewDate.error',
        }[dateName]
      }
    },
    getEventDateMin({ fieldValue: { startOriginalDate, startNewDate } }, dateName) {
      return {
        startOriginalDate: new $Date().startOf('day').format('YYYY-MM-DD'),
        endOriginalDate: startOriginalDate,
        startNewDate: new $Date(startOriginalDate).add(72, 'hours').format('YYYY-MM-DD'),
        endNewDate: startNewDate,
      }[dateName]
    },
    getEventDateMax({ fieldValue: { startOriginalDate } }, dateName) {
      if (dateName === 'startNewDate') {
        return new $Date(startOriginalDate).add(1, 'year').format('YYYY-MM-DD')
      }
    },
    getImportReasonOptions() {
      return [
        'hc_msg_ir_external_area_migration',
        'hc_msg_ir_partnerships_closure',
        'hc_msg_ir_corporate_sale',
        'hc_msg_ir_external_sale',
        'hc_msg_ir_other_product_bonus',
        'hc_msg_ir_previous_account_blocked',
        'hc_msg_ir_platform_change',
        'hc_msg_ir_others',
      ].map(id => ({
        id,
        text: this.$t(`ticket.complementaryData.fields.importReason.options.${id}`),
      }))
    },
    getAccessTypes: function (props) {
      const { fieldValue } = props
      const collaboratorsCenterText = this.$t('ticket.subject.specifications.options.collaboratorsCenter')
      const courseManagementText = this.$t('ticket.subject.specifications.options.courseManagement')
      const accessTypes = {
        [collaboratorsCenterText]: [
          { id: 'report_view', key: 'reports' },
          { id: 'course_admin_management', key: 'courseManagement' },
          { id: 'integrations', key: 'integrations' },
          { id: 'extensions', key: 'extensions' },
        ],
        [courseManagementText]: [
          { id: 'comments_management', key: 'commentManagement' },
          { id: 'guest_import', key: 'guestImport' },
          { id: 'content_edition', key: 'contentEdition' },
        ],
      }

      return accessTypes[fieldValue?.tool]?.map(({ id, key }) => ({
        id: `hc_msg_at_${id}`,
        text: this.$t(`ticket.complementaryData.fields.grantAccess.accessType.${key}`),
      }))
    },
    getChangeRequestReasons() {
      const reasons = ['incorrect-email', 'hacked-email', 'purchase-unification', 'delete-purchase']

      return reasons.map(reason => ({
        id: `hc_msg_crr_${reason.replace('-', '_')}`,
        text: this.$t(`ticket.complementaryData.fields.changeRequestReason.options.${reason}`),
      }))
    },
    getPaymentsAlertLabel: function (props) {
      const { reason, fieldValue } = props ?? {}

      const labels = {
        [SPECIFICATION_TAG.CANCELLATION]: 'paymentsCancel',
        [SPECIFICATION_TAG.SMART_INSTALLMENT_PAYMENT]: 'paymentsSmartInstallment',
      }

      return labels[fieldValue?.specificationSelect]
    },
    getCountryLabel: function (props) {
      const { reason, fieldValue } = props ?? {}

      if (
        [SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER, SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER_AFFILIATE].includes(
          fieldValue?.subjectSelect
        ) ||
        [
          SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA,
          SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS,
          SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return 'countryCurrent'
      }

      const labels = {
        [SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA]: 'countryCurrent',
        [SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA]: 'countryCurrent',
        [SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS]: 'countryCurrent',
        [SPECIFICATION_TAG.CHANGE_REGISTRATION]: 'countryCurrent',
        [SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER_AFFILIATE]: 'countryCurrent',
        [SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER]: 'countryCurrent',
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER]: 'countryCurrent',
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE]: 'countryCurrent',
      }

      return labels[fieldValue?.specificationSelect] || labels[fieldValue?.subjectSelect] || 'country'
    },
    getHotlinkLabel: function (props) {
      const { fieldValue } = props ?? {}

      const labels = {
        [SPECIFICATION_TAG.DISCLOSURE_LINKS]: 'hotlink_disclosure_links',
        [SUBJECT_TAG.TOOLS_AFFILIATE]: 'campaign_disclosure_link',
        [SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE]: 'parametersLink',
        [SPECIFICATION_TAG.TRACKING_PIXEL]: 'parametersLink',
      }

      return labels[fieldValue?.specificationSelect] || labels[fieldValue?.subjectSelect] || 'hotlink'
    },
    getTools: function (props) {
      const { reason, fieldValue } = props ?? {}
      const specification = fieldValue?.specificationSelect

      return TOOLS.filter(({ specifications }) => specifications.includes(specification)).map(({ id, text }) => ({
        id,
        text: this.$t(text),
      }))
    },
    getCredential: function (props) {
      const { reason, fieldValue } = props ?? {}
      const specification = fieldValue?.specificationSelect

      const credentials = [
        {
          text: 'Sandbox',
          specifications: [SPECIFICATION_TAG.CREDENTIALS],
        },
        {
          text: this.$t('ticket.subject.specifications.options.production'),
          specifications: [SPECIFICATION_TAG.CREDENTIALS],
        },
      ]

      return credentials
        .map(({ text, specifications }) => ({
          text,
          view: specifications.includes(specification),
        }))
        .filter(credential => !!credential.view)
    },
    isToolRequired(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.TOOLS_AFFILIATE, SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) ||
        [
          SPECIFICATION_TAG.EMPLOYEE_CENTER,
          SPECIFICATION_TAG.IMPORTING_STUDENTS,
          SPECIFICATION_TAG.TRACKING_PIXEL,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    isCredentialRequired(props) {
      const { fieldValue } = props

      return ![SPECIFICATION_TAG.CREDENTIALS].includes(fieldValue?.specificationSelect)
    },
    showAlertProblemsWithRegistration(props) {
      const { fieldValue } = props

      return [SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA].includes(fieldValue?.specificationSelect)
    },
    showAlertHotmartRecognitions(props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER, SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE].includes(
          fieldValue?.subjectSelect
        )
      )
    },
    showAlertHotmartCard(props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_CARD_PRODUCER, SUBJECT_TAG.HOTMART_CARD_AFFILIATE].includes(fieldValue?.subjectSelect)
      )
    },
    showAlertPromotionalMaterial(props) {
      const { fieldValue } = props

      return (
        [SUBJECT_TAG.TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.PROMOTIONAL_MATERIAL].includes(fieldValue?.specificationSelect)
      )
    },
    resetLocalStorage() {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(ITEM_LOCAL_STORAGE)
      }
    },
    getUploadFileLabel: function (props) {
      const { fieldValue, reason } = props ?? {}

      if (
        [SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFileProblem'
      }

      if (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.IMPORTING_STUDENTS].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFileImportingStudents'
      }

      if (
        [
          SUBJECT_TAG.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
          SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL,
          SUBJECT_TAG.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
        ].includes(fieldValue?.subjectSelect)
      ) {
        return 'uploadFileViolation'
      }

      if (
        ([REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
          [SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT].includes(fieldValue?.specificationSelect)) ||
        [SPECIFICATION_TAG.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFileRequired'
      }

      if (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.BILLET_BANKING,
          SPECIFICATION_TAG.CREDIT_CARD,
          SPECIFICATION_TAG.PIX,
          SPECIFICATION_TAG.OTHER_PAYMENT_METHOD,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFileInformationPayment'
      }

      if (
        ([REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
          [SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER, SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER].includes(
            fieldValue?.specificationSelect
          )) ||
        [
          SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
          SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
          SPECIFICATION_TAG.TRACK_REFUND,
          SPECIFICATION_TAG.CANCEL_SUBSCRIPTION,
          SPECIFICATION_TAG.CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED,
          SPECIFICATION_TAG.REQUEST_REFUND,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFileRefund'
      }

      if (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER,
          SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadFilePaymentVoucher'
      }

      return 'uploadFile'
    },
    renderTransactionsFirstAndSecondCode: function (props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER].includes(fieldValue?.specificationSelect)
      )
    },
    isFileAttachments: function (props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER,
          SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
          SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderAttachment: function (props) {
      const { fieldValue, reason, currentStepIndex } = props

      if (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
          SPECIFICATION_TAG.CHANGE_REGISTRATION,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return false
      }

      if (
        [REASONS_FORM.AFFILIATE_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER, SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE].includes(
          fieldValue?.subjectSelect
        )
      ) {
        return false
      }

      const isAttachmentDefault = (() => {
        if (
          [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
          [SPECIFICATION_TAG.IMPORTING_STUDENTS].includes(fieldValue?.specificationSelect)
        ) {
          return true
        }

        if (
          [REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.BILLET_BANKING,
            SPECIFICATION_TAG.CREDIT_CARD,
            SPECIFICATION_TAG.PIX,
            SPECIFICATION_TAG.OTHER_PAYMENT_METHOD,
            SPECIFICATION_TAG.REQUEST_REFUND,
          ].includes(fieldValue?.specificationSelect)
        ) {
          return true
        }

        if (
          [
            SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
            SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
            SPECIFICATION_TAG.TRACK_REFUND,
            SPECIFICATION_TAG.CANCEL_SUBSCRIPTION,
            SPECIFICATION_TAG.CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED,
            SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
            SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER,
            SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
            SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
          ].includes(fieldValue?.specificationSelect)
        ) {
          return true
        }

        if (
          [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM, REASONS_FORM.BUYER_FORM].includes(reason) &&
          [
            SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT,
            SPECIFICATION_TAG.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED,
            SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
          ].includes(fieldValue?.specificationSelect)
        ) {
          return false
        }

        return this.getUploadFileLabel(props) === 'uploadFile'
      })()

      if (currentStepIndex === 2) return isAttachmentDefault
      if (currentStepIndex === 3) return !isAttachmentDefault

      return false
    },
    renderUnrecognizedCharge: function (props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [
          SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER,
          SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
        ].includes(fieldValue?.specificationSelect)
      )
    },
    renderCreditCardNumbers: function (props) {
      const { fieldValue, reason } = props ?? {}

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER].includes(fieldValue?.specificationSelect)
      )
    },
    getUploadOfficialDocumentsLabel: function (props) {
      const { fieldValue } = props ?? {}

      const labels = {
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER]: 'uploadDocument',
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE]: 'uploadDocument',
        [SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS]: 'uploadFileNewDocument',
      }

      return labels[fieldValue?.subjectSelect] || labels[fieldValue?.specificationSelect] || 'uploadOfficialDocument'
    },
    getUploadDocumentsNameLabel: function (props) {
      const { fieldValue } = props ?? {}

      if (
        [
          SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE,
          SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER,
        ].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadSelfieHoldingNewDocument'
      }

      const labels = {
        [SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER]: 'uploadDocumentSelfie',
        [SPECIFICATION_TAG.CHANGE_REGISTRATION]: 'uploadSelfieHoldingNewDocument',
        [SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY]: 'uploadOfficialDocumentSelfie',
        [SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF]: 'uploadNewCPFDocumentSelfie',
        [SPECIFICATION_TAG.AFFILIATE_PRODUCER_EMANCIPATE_MINOR]: 'uploadOfficialDocumentSelfieRg',
        [SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ]: 'uploadNewCPFDocumentSelfie',
        [SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF]: 'uploadNewCPFDocumentSelfie',
      }

      return (
        labels[fieldValue?.specificationSelect] || labels[fieldValue?.subjectSelect] || 'uploadOfficialDocumentSelfie'
      )
    },
    getSalesPageLinkLabel: function (props) {
      const { reason, fieldValue } = props ?? {}

      if (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL].includes(fieldValue?.subjectSelect)
      ) {
        return 'idOrLinkSalesProduct'
      }

      if (
        [SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER].includes(fieldValue?.subjectSelect) &&
        [SPECIFICATION_TAG.SALES_PAGE].includes(fieldValue?.specificationSelect)
      ) {
        return 'producerSalesPageLink'
      }

      return 'salesPageLink'
    },
    getProductNameLabel: function (props) {
      const { reason, fieldValue } = props ?? {}

      if (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL].includes(fieldValue?.subjectSelect)
      ) {
        return 'yourProductName'
      }

      if (
        ([REASONS_FORM.BUYER_FORM, REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
          [
            SUBJECT_TAG.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
            SUBJECT_TAG.UNSUBSCRIBE,
            SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS,
          ].includes(fieldValue?.subjectSelect)) ||
        [
          SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
          SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
          SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
          SPECIFICATION_TAG.TRACK_REFUND,
          SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
          SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
          SPECIFICATION_TAG.REQUEST_REFUND,
        ].includes(fieldValue?.specificationSelect)
      ) {
        return 'productName'
      }

      return 'nameCourseOurProduct'
    },
    getUploadFileCopyright: function (props) {
      const { reason, fieldValue } = props ?? {}

      if (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        [SPECIFICATION_TAG.TRADEMARK_VIOLATION].includes(fieldValue?.specificationSelect)
      ) {
        return 'uploadTrademarkFileCopyright'
      }

      return 'uploadFileCopyright'
    },
  },
}
