const countries = [
  { id: 'country_brasil_privacy', i18nKey: 'country.brasil' },
  { id: 'country_portugal_privacy', i18nKey: 'country.portugal' },
  { id: 'country_unitedstates_privacy', i18nKey: 'country.unitedstates' },
  { id: 'country_unitedkingdom_privacy', i18nKey: 'country.unitedkingdom' },
  { id: 'country_afghanistan_privacy', i18nKey: 'country.afghanistan' },
  { id: 'country_albania_privacy', i18nKey: 'country.albania' },
  { id: 'country_algeria_privacy', i18nKey: 'country.algeria' },
  { id: 'country_americansamoa_privacy', i18nKey: 'country.americansamoa' },
  { id: 'country_andorra_privacy', i18nKey: 'country.andorra' },
  { id: 'country_angola_privacy', i18nKey: 'country.angola' },
  { id: 'country_anguilla_privacy', i18nKey: 'country.anguilla' },
  { id: 'country_antarctica_privacy', i18nKey: 'country.antarctica' },
  { id: 'country_antiguaandbarbuda_privacy', i18nKey: 'country.antiguaandbarbuda' },
  { id: 'country_argentina_privacy', i18nKey: 'country.argentina' },
  { id: 'country_armenia_privacy', i18nKey: 'country.armenia' },
  { id: 'country_aruba_privacy', i18nKey: 'country.aruba' },
  { id: 'country_australia_privacy', i18nKey: 'country.australia' },
  { id: 'country_austria_privacy', i18nKey: 'country.austria' },
  { id: 'country_azerbaijan_privacy', i18nKey: 'country.azerbaijan' },
  { id: 'country_bahamas_privacy', i18nKey: 'country.bahamas' },
  { id: 'country_bahrain_privacy', i18nKey: 'country.bahrain' },
  { id: 'country_bangladesh_privacy', i18nKey: 'country.bangladesh' },
  { id: 'country_barbados_privacy', i18nKey: 'country.barbados' },
  { id: 'country_belgium_privacy', i18nKey: 'country.belgium' },
  { id: 'country_belize_privacy', i18nKey: 'country.belize' },
  { id: 'country_benin_privacy', i18nKey: 'country.benin' },
  { id: 'country_bermuda_privacy', i18nKey: 'country.bermuda' },
  { id: 'country_bhutan_privacy', i18nKey: 'country.bhutan' },
  { id: 'country_bolivia_privacy', i18nKey: 'country.bolivia' },
  { id: 'country_bosniaandherzegovina_privacy', i18nKey: 'country.bosniaandherzegovina' },
  { id: 'country_botswana_privacy', i18nKey: 'country.botswana' },
  { id: 'country_bouvetisland_privacy', i18nKey: 'country.bouvetisland' },
  { id: 'country_britishindianocenterritory_privacy', i18nKey: 'country.britishindianoceanterritory' },
  { id: 'country_bruneidarussalam_privacy', i18nKey: 'country.bruneidarussalam' },
  { id: 'country_bulgaria_privacy', i18nKey: 'country.bulgaria' },
  { id: 'country_burkinafaso_privacy', i18nKey: 'country.burkinafaso' },
  { id: 'country_burundi_privacy', i18nKey: 'country.burundi' },
  { id: 'country_cambodia_privacy', i18nKey: 'country.cambodia' },
  { id: 'country_cameroon_privacy', i18nKey: 'country.cameroon' },
  { id: 'country_canada_privacy', i18nKey: 'country.canada' },
  { id: 'country_capeverde_privacy', i18nKey: 'country.capeverde' },
  { id: 'country_caymanislands_privacy', i18nKey: 'country.caymanislands' },
  { id: 'country_chad_privacy', i18nKey: 'country.chad' },
  { id: 'country_chile_privacy', i18nKey: 'country.chile' },
  { id: 'country_china_privacy', i18nKey: 'country.china' },
  { id: 'country_christmasisland_privacy', i18nKey: 'country.christmasisland' },
  { id: 'country_cocoskeelingislands_privacy', i18nKey: 'country.cocoskeelingislands' },
  { id: 'country_colombia_privacy', i18nKey: 'country.colombia' },
  { id: 'country_comoros_privacy', i18nKey: 'country.comoros' },
  { id: 'country_contodemocraticrepublicofthe_privacy', i18nKey: 'country.congothedemocraticrepublicofthe' },
  { id: 'country_cookislands_privacy', i18nKey: 'country.cookislands' },
  { id: 'country_costarica_privacy', i18nKey: 'country.costarica' },
  { id: 'country_cotedivoire_privacy', i18nKey: 'country.cotedivoire' },
  { id: 'country_croatia_privacy', i18nKey: 'country.croatia' },
  { id: 'country_cyprus_privacy', i18nKey: 'country.cyprus' },
  { id: 'country_czechrepublic_privacy', i18nKey: 'country.czechrepublic' },
  { id: 'country_denmark_privacy', i18nKey: 'country.denmark' },
  { id: 'country_djibouti_privacy', i18nKey: 'country.djibouti' },
  { id: 'country_dominica_privacy', i18nKey: 'country.dominica' },
  { id: 'country_dominicanrepublic_privacy', i18nKey: 'country.dominicanrepublic' },
  { id: 'country_ecuador_privacy', i18nKey: 'country.ecuador' },
  { id: 'country_egypt_privacy', i18nKey: 'country.egypt' },
  { id: 'country_elsalvador_privacy', i18nKey: 'country.elsalvador' },
  { id: 'country_equatorialguinea_privacy', i18nKey: 'country.equatorialguinea' },
  { id: 'country_eritrea_privacy', i18nKey: 'country.eritrea' },
  { id: 'country_estonia_privacy', i18nKey: 'country.estonia' },
  { id: 'country_ethiopia_privacy', i18nKey: 'country.ethiopia' },
  { id: 'country_falklandislandsmalvinas_privacy', i18nKey: 'country.falklandislandsmalvinas' },
  { id: 'country_faroeislands_privacy', i18nKey: 'country.faroeislands' },
  { id: 'country_fiji_privacy', i18nKey: 'country.fiji' },
  { id: 'country_finland_privacy', i18nKey: 'country.finland' },
  { id: 'country_france_privacy', i18nKey: 'country.france' },
  { id: 'country_frenchguiana_privacy', i18nKey: 'country.frenchguiana' },
  { id: 'country_frenchpolynesia_privacy', i18nKey: 'country.frenchpolynesia' },
  { id: 'country_frenchsouthernterritories_privacy', i18nKey: 'country.frenchsouthernterritories' },
  { id: 'country_gabon_privacy', i18nKey: 'country.gabon' },
  { id: 'country_gambia_privacy', i18nKey: 'country.gambia' },
  { id: 'country_georgia_privacy', i18nKey: 'country.georgia' },
  { id: 'country_germany_privacy', i18nKey: 'country.germany' },
  { id: 'country_ghana_privacy', i18nKey: 'country.ghana' },
  { id: 'country_gibraltar_privacy', i18nKey: 'country.gibraltar' },
  { id: 'country_greece_privacy', i18nKey: 'country.greece' },
  { id: 'country_greenland_privacy', i18nKey: 'country.greenland' },
  { id: 'country_grenada_privacy', i18nKey: 'country.grenada' },
  { id: 'country_guadeloupe_privacy', i18nKey: 'country.guadeloupe' },
  { id: 'country_guam_privacy', i18nKey: 'country.guam' },
  { id: 'country_guatemala_privacy', i18nKey: 'country.guatemala' },
  { id: 'country_guinea_privacy', i18nKey: 'country.guinea' },
  { id: 'country_guineabissau_privacy', i18nKey: 'country.guineabissau' },
  { id: 'country_guyana_privacy', i18nKey: 'country.guyana' },
  { id: 'country_haiti_privacy', i18nKey: 'country.haiti' },
  { id: 'country_heardislandandmcdonaldislands_privacy', i18nKey: 'country.heardislandandmcdonaldislands' },
  { id: 'country_holyseevaticancitystate_privacy', i18nKey: 'country.holyseevaticancitystate' },
  { id: 'country_honduras_privacy', i18nKey: 'country.honduras' },
  { id: 'country_hongkong_privacy', i18nKey: 'country.hongkong' },
  { id: 'country_hungary_privacy', i18nKey: 'country.hungary' },
  { id: 'country_iceland_privacy', i18nKey: 'country.iceland' },
  { id: 'country_india_privacy', i18nKey: 'country.india' },
  { id: 'country_indonesia_privacy', i18nKey: 'country.indonesia' },
  { id: 'country_ireland_privacy', i18nKey: 'country.ireland' },
  { id: 'country_israel_privacy', i18nKey: 'country.israel' },
  { id: 'country_italy_privacy', i18nKey: 'country.italy' },
  { id: 'country_jamaica_privacy', i18nKey: 'country.jamaica' },
  { id: 'country_japan_privacy', i18nKey: 'country.japan' },
  { id: 'country_jordan_privacy', i18nKey: 'country.jordan' },
  { id: 'country_kazakhstan_privacy', i18nKey: 'country.kazakhstan' },
  { id: 'country_kenya_privacy', i18nKey: 'country.kenya' },
  { id: 'country_kiribati_privacy', i18nKey: 'country.kiribati' },
  { id: 'country_kuwait_privacy', i18nKey: 'country.kuwait' },
  { id: 'country_kyrgyzstan_privacy', i18nKey: 'country.kyrgyzstan' },
  { id: 'country_laopeoplesdemocraticrepublic_privacy', i18nKey: 'country.laopeoplesdemocraticrepublic' },
  { id: 'country_latvia_privacy', i18nKey: 'country.latvia' },
  { id: 'country_lesotho_privacy', i18nKey: 'country.lesotho' },
  { id: 'country_liberia_privacy', i18nKey: 'country.liberia' },
  { id: 'country_liechtenstein_privacy', i18nKey: 'country.liechtenstein' },
  { id: 'country_lithuania_privacy', i18nKey: 'country.lithuania' },
  { id: 'country_luxembourg_privacy', i18nKey: 'country.luxembourg' },
  { id: 'country_macao_privacy', i18nKey: 'country.macao' },
  {
    id: 'country_macedoniatheformeryugoslavrepublicof_privacy',
    i18nKey: 'country.macedoniatheformeryugoslavrepublicof',
  },
  { id: 'country_madagascar_privacy', i18nKey: 'country.madagascar' },
  { id: 'country_malawi_privacy', i18nKey: 'country.malawi' },
  { id: 'country_malaysia_privacy', i18nKey: 'country.malaysia' },
  { id: 'country_maldives_privacy', i18nKey: 'country.maldives' },
  { id: 'country_malta_privacy', i18nKey: 'country.malta' },
  { id: 'country_marshallislands_privacy', i18nKey: 'country.marshallislands' },
  { id: 'country_martinique_privacy', i18nKey: 'country.martinique' },
  { id: 'country_mauritania_privacy', i18nKey: 'country.mauritania' },
  { id: 'country_mauritius_privacy', i18nKey: 'country.mauritius' },
  { id: 'country_mayotte_privacy', i18nKey: 'country.mayotte' },
  { id: 'country_mexico_privacy', i18nKey: 'country.mexico' },
  { id: 'country_micronesiafederatedstatesof_privacy', i18nKey: 'country.micronesiafederatedstatesof' },
  { id: 'country_moldovarepublicof_privacy', i18nKey: 'country.moldovarepublicof' },
  { id: 'country_monaco_privacy', i18nKey: 'country.monaco' },
  { id: 'country_mongolia_privacy', i18nKey: 'country.mongolia' },
  { id: 'country_montserrat_privacy', i18nKey: 'country.montserrat' },
  { id: 'country_morocco_privacy', i18nKey: 'country.morocco' },
  { id: 'country_mozambique_privacy', i18nKey: 'country.mozambique' },
  { id: 'country_myanmar_privacy', i18nKey: 'country.myanmar' },
  { id: 'country_namibia_privacy', i18nKey: 'country.namibia' },
  { id: 'country_nauru_privacy', i18nKey: 'country.nauru' },
  { id: 'country_nepal_privacy', i18nKey: 'country.nepal' },
  { id: 'country_netherlands_privacy', i18nKey: 'country.netherlands' },
  { id: 'country_netherlandsantilles_privacy', i18nKey: 'country.netherlandsantilles' },
  { id: 'country_newcaledonia_privacy', i18nKey: 'country.newcaledonia' },
  { id: 'country_newzealand_privacy', i18nKey: 'country.newzealand' },
  { id: 'country_niger_privacy', i18nKey: 'country.niger' },
  { id: 'country_nigeria_privacy', i18nKey: 'country.nigeria' },
  { id: 'country_niue_privacy', i18nKey: 'country.niue' },
  { id: 'country_norfolkisland_privacy', i18nKey: 'country.norfolkisland' },
  { id: 'country_northernmarianaislands_privacy', i18nKey: 'country.northernmarianaislands' },
  { id: 'country_norway_privacy', i18nKey: 'country.norway' },
  { id: 'country_oman_privacy', i18nKey: 'country.oman' },
  { id: 'country_pakistan_privacy', i18nKey: 'country.pakistan' },
  { id: 'country_palau_privacy', i18nKey: 'country.palau' },
  { id: 'country_palestinianterritoryoccupied_privacy', i18nKey: 'country.palestinianterritoryoccupied' },
  { id: 'country_panama_privacy', i18nKey: 'country.panama' },
  { id: 'country_papuanewguinea_privacy', i18nKey: 'country.papuanewguinea' },
  { id: 'country_paraguay_privacy', i18nKey: 'country.paraguay' },
  { id: 'country_peru_privacy', i18nKey: 'country.peru' },
  { id: 'country_philippines_privacy', i18nKey: 'country.philippines' },
  { id: 'country_pitcairn_privacy', i18nKey: 'country.pitcairn' },
  { id: 'country_poland_privacy', i18nKey: 'country.poland' },
  { id: 'country_puertorico_privacy', i18nKey: 'country.puertorico' },
  { id: 'country_qatar_privacy', i18nKey: 'country.qatar' },
  { id: 'country_reunion_privacy', i18nKey: 'country.reunion' },
  { id: 'country_romania_privacy', i18nKey: 'country.romania' },
  { id: 'country_russianfederation_privacy', i18nKey: 'country.russianfederation' },
  { id: 'country_rwanda_privacy', i18nKey: 'country.rwanda' },
  { id: 'country_sainthelena_privacy', i18nKey: 'country.sainthelena' },
  { id: 'country_saintkittsandnevis_privacy', i18nKey: 'country.saintkittsandnevis' },
  { id: 'country_saintlucia_privacy', i18nKey: 'country.saintlucia' },
  { id: 'country_saintpierreandmiquelon_privacy', i18nKey: 'country.saintpierreandmiquelon' },
  { id: 'country_saintvincentandthegrenadines_privacy', i18nKey: 'country.saintvincentandthegrenadines' },
  { id: 'country_samoa_privacy', i18nKey: 'country.samoa' },
  { id: 'country_sanmarino_privacy', i18nKey: 'country.sanmarino' },
  { id: 'country_saotomeandprincipe_privacy', i18nKey: 'country.saotomeandprincipe' },
  { id: 'country_saudiarabia_privacy', i18nKey: 'country.saudiarabia' },
  { id: 'country_senegal_privacy', i18nKey: 'country.senegal' },
  { id: 'country_serbia_privacy', i18nKey: 'country.serbia' },
  { id: 'country_seychelles_privacy', i18nKey: 'country.seychelles' },
  { id: 'country_sierraleone_privacy', i18nKey: 'country.sierraleone' },
  { id: 'country_singapore_privacy', i18nKey: 'country.singapore' },
  { id: 'country_slovakia_privacy', i18nKey: 'country.slovakia' },
  { id: 'country_slovenia_privacy', i18nKey: 'country.slovenia' },
  { id: 'country_solomonislands_privacy', i18nKey: 'country.solomonislands' },
  { id: 'country_southafrica_privacy', i18nKey: 'country.southafrica' },
  {
    id: 'country_southgeorgiaandthesouthsandwichislands_privacy',
    i18nKey: 'country.southgeorgiaandthesouthsandwichislands',
  },
  { id: 'country_spain_privacy', i18nKey: 'country.spain' },
  { id: 'country_srilanka_privacy', i18nKey: 'country.srilanka' },
  { id: 'country_suriname_privacy', i18nKey: 'country.suriname' },
  { id: 'country_svalbardandjanmayen_privacy', i18nKey: 'country.svalbardandjanmayen' },
  { id: 'country_swaziland_privacy', i18nKey: 'country.swaziland' },
  { id: 'country_sweden_privacy', i18nKey: 'country.sweden' },
  { id: 'country_switzerland_privacy', i18nKey: 'country.switzerland' },
  { id: 'country_taiwanprovinceofchina_privacy', i18nKey: 'country.taiwanprovinceofchina' },
  { id: 'country_tajikistan_privacy', i18nKey: 'country.tajikistan' },
  { id: 'country_tanzaniaunitedrepublicof_privacy', i18nKey: 'country.tanzaniaunitedrepublicof' },
  { id: 'country_thailand_privacy', i18nKey: 'country.thailand' },
  { id: 'country_timorleste_privacy', i18nKey: 'country.timorleste' },
  { id: 'country_togo_privacy', i18nKey: 'country.togo' },
  { id: 'country_tokelau_privacy', i18nKey: 'country.tokelau' },
  { id: 'country_tonga_privacy', i18nKey: 'country.tonga' },
  { id: 'country_trinidadandtobago_privacy', i18nKey: 'country.trinidadandtobago' },
  { id: 'country_tunisia_privacy', i18nKey: 'country.tunisia' },
  { id: 'country_turkey_privacy', i18nKey: 'country.turkey' },
  { id: 'country_turkmenistan_privacy', i18nKey: 'country.turkmenistan' },
  { id: 'country_turksandcaicosislands_privacy', i18nKey: 'country.turksandcaicosislands' },
  { id: 'country_tuvalu_privacy', i18nKey: 'country.tuvalu' },
  { id: 'country_uganda_privacy', i18nKey: 'country.uganda' },
  { id: 'country_ukraine_privacy', i18nKey: 'country.ukraine' },
  { id: 'country_unitedarabemirates_privacy', i18nKey: 'country.unitedarabemirates' },
  { id: 'country_unitedstatesminoroutlyingislands_privacy', i18nKey: 'country.unitedstatesminoroutlyingislands' },
  { id: 'country_uruguay_privacy', i18nKey: 'country.uruguay' },
  { id: 'country_uzbekistan_privacy', i18nKey: 'country.uzbekistan' },
  { id: 'country_vanuatu_privacy', i18nKey: 'country.vanuatu' },
  { id: 'country_vietnam_privacy', i18nKey: 'country.vietnam' },
  { id: 'country_virginislandsbritish_privacy', i18nKey: 'country.virginislandsbritish' },
  { id: 'country_virginislandsus_privacy', i18nKey: 'country.virginislandsus' },
  { id: 'country_wallisandfutuna_privacy', i18nKey: 'country.wallisandfutuna' },
  { id: 'country_westernsahara_privacy', i18nKey: 'country.westernsahara' },
  { id: 'country_zambia_privacy', i18nKey: 'country.zambia' },
  { id: 'country_montenegro_privacy', i18nKey: 'country.montenegro' },
  { id: 'country_korearepublicof_privacy', i18nKey: 'country.korearepublicof' },
  { id: 'country_canary_privacy', i18nKey: 'country.canary' },
  { id: 'country_azores_privacy', i18nKey: 'country.azores' },
  { id: 'country_madeira_privacy', i18nKey: 'country.madeira' },
  { id: 'country_ceuta_privacy', i18nKey: 'country.ceuta' },
  { id: 'country_melilla_privacy', i18nKey: 'country.melilla' },
  { id: 'country_curacao_privacy', i18nKey: 'country.curacao' },
]

const usRegions = [
  { id: 'region_alabama', value: 'Alabama' },
  { id: 'region_alaska', value: 'Alaska' },
  { id: 'region_arizona', value: 'Arizona' },
  { id: 'region_arkansas', value: 'Arkansas' },
  { id: 'region_california', value: 'California' },
  { id: 'region_colorado', value: 'Colorado' },
  { id: 'region_connecticut', value: 'Connecticut' },
  { id: 'region_delaware', value: 'Delaware' },
  { id: 'region_district_of_columbia', value: 'District of Columbia' },
  { id: 'region_florida', value: 'Florida' },
  { id: 'region_georgia', value: 'Georgia' },
  { id: 'region_hawaii', value: 'Hawaii' },
  { id: 'region_idaho', value: 'Idaho' },
  { id: 'region_illinois', value: 'Illinois' },
  { id: 'region_indiana', value: 'Indiana' },
  { id: 'region_iowa', value: 'Iowa' },
  { id: 'region_kansas', value: 'Kansas' },
  { id: 'region_kentucky', value: 'Kentucky' },
  { id: 'region_louisiana', value: 'Louisiana' },
  { id: 'region_maine', value: 'Maine' },
  { id: 'region_maryland', value: 'Maryland' },
  { id: 'region_massachusetts', value: 'Massachusetts' },
  { id: 'region_michigan', value: 'Michigan' },
  { id: 'region_minnesota', value: 'Minnesota' },
  { id: 'region_mississippi', value: 'Mississippi' },
  { id: 'region_missouri', value: 'Missouri' },
  { id: 'region_montana', value: 'Montana' },
  { id: 'region_nebraska', value: 'Nebraska' },
  { id: 'region_nevada', value: 'Nevada' },
  { id: 'region_new_hampshire', value: 'New Hampshire' },
  { id: 'region_new_jersey', value: 'New Jersey' },
  { id: 'region_new_mexico', value: 'New Mexico' },
  { id: 'region_new_york', value: 'New York' },
  { id: 'region_north_carolina', value: 'North Carolina' },
  { id: 'region_north_dakota', value: 'North Dakota' },
  { id: 'region_ohio', value: 'Ohio' },
  { id: 'region_oklahoma', value: 'Oklahoma' },
  { id: 'region_oregon', value: 'Oregon' },
  { id: 'region_pennsylvania', value: 'Pennsylvania' },
  { id: 'region_rhode_island', value: 'Rhode Island' },
  { id: 'region_south_carolina', value: 'South Carolina' },
  { id: 'region_south_dakota', value: 'South Dakota' },
  { id: 'region_tennessee', value: 'Tennessee' },
  { id: 'region_texas', value: 'Texas' },
  { id: 'region_utah', value: 'Utah' },
  { id: 'region_vermont', value: 'Vermont' },
  { id: 'region_virginia', value: 'Virginia' },
  { id: 'region_washington', value: 'Washington' },
  { id: 'region_west_virginia', value: 'West Virginia' },
  { id: 'region_wisconsin', value: 'Wisconsin' },
  { id: 'region_wyoming', value: 'Wyoming' },
]

export { countries, usRegions }
