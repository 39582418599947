import { mapGetters } from 'vuex'
import { getModal } from '~/enum/form/modals'
import articleHistory from '~/mixins/article-history'
import chatbot from '~/mixins/chatbot'

export default {
  mixins: [articleHistory, chatbot],
  data: () => ({
    isOpen: false,
    active: false,
  }),
  computed: {
    ...mapGetters('ticketFormAISummary', ['shouldRenderAISummary']),
    modal() {
      return getModal.call(this)
    },
    shouldRender() {
      return Boolean(this.modal?.shouldRender && this.active)
    },
  },
  watch: {
    modal: {
      async handler() {
        await this.openModal()
        this.sendShowModalEvent()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async openModal() {
      await this.$nextTick()
      await this.getToggle()
      this.isOpen = this.shouldRender
    },
    closeModal() {
      this.isOpen = false
    },
    closeModalAndResetNavigation() {
      if (!this.isOpen) return
      this.closeModal()
      this.sendCloseModalEvent()
      this.resetNavigation()
    },
    resetNavigation() {
      window.location.search = ''
    },
    sendEvent(override = {}) {
      if (this.shouldRender && this.modal?.dataLayer && process.client) {
        const event = {
          event: 'custom_event',
          ...this.modal.dataLayer,
          ...override,
        }
        dataLayer.push(event)
      }
    },
    sendCloseModalEvent() {
      this.sendEvent({ event_details: 'Click::Close' })
    },
    sendOpenRequestEvent() {
      this.sendEvent({ event_details: 'Click::OpenRequest' })
    },
    sendShowModalEvent() {
      this.sendEvent()
    },
    async getToggle() {
      if (this.modal?.toggle) {
        const { enabled } = await this.$toggle.retrieve(this.modal.toggle)
        this.active = enabled
        return
      }

      this.active = true
    },
  },
}
