export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Object,
      default: null,
    },
  },
}
