const mediaQuery = {
  xs: {
    max: 575,
  },
  sm: {
    min: 576,
    max: 767,
  },
  md: {
    min: 768,
    max: 991,
  },
  lg: {
    min: 992,
    max: 1999,
  },
  xl: {
    min: 1200,
  },
}
export default {
  data: () => ({
    windowWidth: 0,
    windowHeight: 0,
    wXS: false,
    wSM: false,
    wMD: false,
    wLG: false,
    wXL: false,
  }),
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)
      this.getWindowWidth()
      this.getWindowHeight()
    })
  },
  methods: {
    getWindowWidth(event) {
      let w = document.documentElement.clientWidth
      this.windowWidth = w

      this.wXS = w <= mediaQuery.xs.max
      this.wSM = w >= mediaQuery.sm.min && w <= mediaQuery.sm.max
      this.wMD = w >= mediaQuery.md.min && w <= mediaQuery.md.max
      this.wLG = w >= mediaQuery.lg.min && w <= mediaQuery.lg.max
      this.wXL = w >= mediaQuery.xl.min
    },

    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight
    },
  },
  computed: {
    isMobile() {
      return this.wXS || this.wSM || this.wMD
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  },
}
