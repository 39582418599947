import { mapActions, mapGetters } from 'vuex'
import AISummary from '~/mixins/ai-summary'
import trackAiUse from '~/mixins/track-ai-use'
import { getReasonKeyBySlug, getSpecificationKeyBySlug, getSubjectKeyBySlug } from '~/utils/sharedHelpers'

export default {
  mixins: [trackAiUse, AISummary],
  props: {
    username: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    summary: null,
    responseId: null,
  }),
  computed: {
    ...mapGetters('ticketFormAISummary', ['shouldRenderAISummary']),
    ...mapGetters('ticketFormStepNavigation', ['currentStepIndex']),
    ready() {
      return this.shouldRenderAISummary && this.currentStepIndex === 3
    },
  },
  methods: {
    ...mapActions('ticketFormAISummary', ['setShouldRenderAISummary']),
    getAISummary() {
      const payload = {
        username: this.username,
        message: this.message,
        subject: {
          key: this.$route.query.subject,
          value: this.$t(getSubjectKeyBySlug(this.$route.query.subject)),
        },
        reason: {
          key: this.$route.query.reason,
          value: this.$t(getReasonKeyBySlug(this.$route.query.reason)),
        },
        specification: !!this.$route.query.specification
          ? {
              key: this.$route.query.specification,
              value: this.$t(getSpecificationKeyBySlug(this.$route.query.specification)),
            }
          : undefined,
      }
      return this.$faqgptIntegration.getSummaryScreening(payload)
    },
    trackingShowSummary() {
      this.setAiUse(this.origins.TICKET_SCREENING)
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'request_screening',
        event_details: 'type_request',
      })
      this.$datahub.aiTelemetry({
        origin: 'SUMMARY_SCREENING',
        language: this.$i18n.locale,
        context: [this.$route.query.subject, this.$route.query.reason, this.$route.query.specification]
          .filter(Boolean)
          .join('.'),
      })
    },
  },
  watch: {
    ready: {
      async handler(isReady) {
        if (isReady) {
          try {
            this.isLoading = true
            const { summary, responseId } = await this.getAISummary()
            this.summary = this.formatSummaryLinks(summary)
            this.responseId = responseId
            this.trackingShowSummary()
          } catch (e) {
            this.setShouldRenderAISummary(false)
          } finally {
            this.isLoading = false
          }
        }
      },
      immediate: true,
    },
  },
}
