import { PRODUCER_REFUND_TAG } from './constants'

export const PRODUCER_REFUND = [
  {
    key: 'productSalesClosed',
    tag: PRODUCER_REFUND_TAG.PRODUCT_SALES_CLOSED,
    slug: 'product-sales-closed',
  },
  {
    key: 'technicalIssues',
    tag: PRODUCER_REFUND_TAG.TECHNICAL_ISSUES,
    slug: 'technical-issues',
  },
  {
    key: 'productDidNotMeetExpectations',
    tag: PRODUCER_REFUND_TAG.PRODUCT_DID_NOT_MEET_EXPECTATIONS,
    slug: 'product-did-not-meet-expectations',
  },
  {
    key: 'customerNotSatisfied',
    tag: PRODUCER_REFUND_TAG.CUSTOMER_NOT_SATISFIED,
    slug: 'customer-not-satisfied',
  },
  {
    key: 'customerNotSetSchedule',
    tag: PRODUCER_REFUND_TAG.CUSTOMER_NOT_SET_SCHEDULE,
    slug: 'customer-not-set-schedule',
  },
  {
    key: 'financialIssue',
    tag: PRODUCER_REFUND_TAG.FINANCIAL_ISSUE,
    slug: 'financial-issue',
  },
  {
    key: 'mySalesStrategy',
    tag: PRODUCER_REFUND_TAG.MY_SALES_STRATEGY,
    slug: 'my-sales-strategy',
  },
]
