export default {
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    isCenterContainer: Boolean,
    isLastStep: Boolean,
  },
}
