import { getSubjectKeyBySlug } from '~/utils/sharedHelpers'
import { SLUG } from '~/enum/form/constants'

export default {
  props: {
    reason: String,
    updateReason: Function,
  },
  data: () => ({
    SLUG,
  }),
  methods: {
    getSubjectKeyBySlug,
  },
}
