export default {
  props: {
    id: String,
    name: String,
    minlength: Number,
    maxlength: {
      type: Number,
      default: 300,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: [Number, String],
    pattern: [RegExp, String],
    validate: Function,
    label: String,
    value: String,
    type: String,
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    onfocus: Function,
    mask: {
      type: Function,
      default: value => value,
    },
    placeholder: {
      type: String,
      default: '',
    },
    oninput: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      alreadyFocused: false,
      isFocused: false,
    }
  },
  computed: {
    invalid: function () {
      return this.invalidRequired || this.invalidPattern || this.dontPassValidateMethod
    },
    invalidRequired: function () {
      const invalid = this.required && !this.value

      return invalid ? 'formValidation.required' : false
    },
    dontPassValidateMethod: function () {
      return this.validate ? this.validate(this.value) : false
    },
    invalidPattern: function () {
      if (this.pattern) {
        const patternRegex = RegExp(this.pattern, 'ig')
        const invalid = !patternRegex.test(this.value)
        if (invalid) {
          if (this.type === 'email') {
            return 'formValidation.email'
          } else {
            return 'formValidation.pattern'
          }
        } else {
          return false
        }
      }

      return false
    },
    styleInvalidInput: function () {
      return this.invalid && this.alreadyFocused ? 'is-invalid' : ''
    },
  },
  methods: {
    enforce_maxlength: function (event) {
      if (this.type === 'number') {
        var t = event.target
        if (t.hasAttribute('maxlength')) {
          t.value = t.value.slice(0, t.getAttribute('maxlength'))
        }
      }
    },
    onInput: function (value) {
      this.$emit('input', value)
    },
    blurFocused: function () {
      this.alreadyFocused = false
    },
    focus: function () {
      this.$refs.input.focus()
      this.alreadyFocused = true
    },
    reset: function () {
      this.isFocused = false
      this.alreadyFocused = false
    },
  },
}
