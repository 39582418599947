import { computed, ref, useContext } from '@nuxtjs/composition-api'
import { useMask, useOpenTicketFlow, useValidation } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step3-cnpj',
  setup(__props, { expose }) {

const { $t, store } = useContext()
const { inTheFlow } = useOpenTicketFlow()
const mask = useMask()
const validate = useValidation()

const inputRef = ref(null)
const value = ref('')

const shouldRenderChangeOwnership = computed(
  () => store.getters['ticketFormChangeOwnerShip/shouldRenderChangeOwnership']
)

const maskCnpj = computed(() => (shouldRenderChangeOwnership.value ? mask.cnpj : undefined))
const validateCnpj = computed(() => (shouldRenderChangeOwnership.value ? validate.cnpj : undefined))

const shouldRender = computed(
  () =>
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug],
    }) ||
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [SUBJECT.HOTMART_RECOGNITIONS_PRODUCER.slug, SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE.slug],
    })
)

const label = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [SUBJECT.HOTMART_RECOGNITIONS_PRODUCER.slug, SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE.slug],
    })
  ) {
    return $t('ticket.complementaryData.fields.document.title')
  }

  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug],
    })
  ) {
    return $t('ticket.complementaryData.fields.accountCNPJ.title_v2')
  }
  return $t('ticket.complementaryData.fields.accountCNPJ.title')
})

const placeholder = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [SUBJECT.HOTMART_RECOGNITIONS_PRODUCER.slug, SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE.slug],
    })
  ) {
    return $t('ticket.complementaryData.fields.document.placeholder')
  }

  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug],
    })
  ) {
    return $t('ticket.complementaryData.fields.accountCNPJ.placeholder_v2')
  }
  return $t('ticket.complementaryData.fields.accountCNPJ.placeholder')
})

const helpMessage = computed(() => {
  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug],
    })
  ) {
    return $t('ticket.complementaryData.fields.accountCNPJ.helpText')
  }
  return undefined
})

function updateFormData(formData) {
  if (value.value) formData.set('cnpj', value.value)
}

expose({
  invalid: computed(() => inputRef.value?.invalid),
  required: computed(() => inputRef.value?.required),
  focus: () => inputRef.value?.focus(),
  updateFormData,
  value,
})

return { __sfc: true,$t, store, inTheFlow, mask, validate, inputRef, value, shouldRenderChangeOwnership, maskCnpj, validateCnpj, shouldRender, label, placeholder, helpMessage, updateFormData }
}

}