import { computed } from '@nuxtjs/composition-api'


export default {
  __name: 'TicketModalBody',
  props: {
  modal: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;



const items = computed(() => {
  return props.modal.items
    .filter(item => item.shouldRender)
    .map(item => {
      const bind = {}
      if (/^a$/.test(item.is)) {
        bind.rel = 'noopener noreferrer'
        bind.class = '_text-2 _text-decoration-none _mb-5'
        if (item.to) bind.href = item.to
        if (item.target) bind.target = item.target
      }
      if (/^li$/.test(item.is)) {
        bind.class = '_text-2 _text-gray-500 _mb-5 _ml-5'
      }
      return {
        ...item,
        bind,
      }
    })
})

function handleClick(item) {
  item.onClick?.()
  if (props.modal.dataLayer && item.dataLayer) {
    dataLayer.push({
      event: 'custom_event',
      ...props.modal.dataLayer,
      ...item.dataLayer,
    })
  }
}

return { __sfc: true,props, items, handleClick }
}

}