import { REASONS_FORM, SPECIFICATION_TAG, SUBJECT_TAG } from '~/enum/form/constants'

export default {
  methods: {
    dataPrivacyForm(reason) {
      return [REASONS_FORM.DATA_PRIVACY_FORM].includes(reason)
    },
    buyerForm(reason, subject, specification) {
      const CHANGE_IN_REGISTRATION_INFORMATION = [SUBJECT_TAG.CHANGE_IN_REGISTRATION_INFORMATION].includes(subject)
      const REFUND_OR_CANCELLATION =
        [SUBJECT_TAG.REFUND_OR_CANCELLATION].includes(subject) &&
        [SPECIFICATION_TAG.CANCEL_REFUND_REQUEST, SPECIFICATION_TAG.REQUEST_REFUND].includes(specification)

      return (
        [REASONS_FORM.BUYER_FORM].includes(reason) && (CHANGE_IN_REGISTRATION_INFORMATION || REFUND_OR_CANCELLATION)
      )
    },
    sparkleForm(reason, subject) {
      return (
        [REASONS_FORM.SPARKLE_FORM].includes(reason) &&
        [
          SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA,
          SUBJECT_TAG.ACCOUNT_LOCKOUT_DISPUTE,
          SUBJECT_TAG.DELETE_ACCOUNT,
        ].includes(subject)
      )
    },
    producerOrCoProducerForm(reason, subject, specification) {
      const DATA_CHANGE = [
        SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER,
        SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER,
        SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER,
      ].includes(subject)
      const HELP_MY_STUDENTS =
        [SUBJECT_TAG.HELP_MY_STUDENTS].includes(subject) &&
        [SPECIFICATION_TAG.CHANGE_IN_REGISTRATION_INFORMATION].includes(specification)
      const CHANGE_REGISTRATION =
        [SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER] &&
        [
          SPECIFICATION_TAG.CHANGE_REGISTRATION,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
        ].includes(specification)
      const RECOGNITIONS_PRODUCER = [SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER].includes(subject)
      return (
        [REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM].includes(reason) &&
        (DATA_CHANGE || HELP_MY_STUDENTS || CHANGE_REGISTRATION || RECOGNITIONS_PRODUCER)
      )
    },
    affiliateForm(reason, subject, specification) {
      const CHANGE_REGISTRATION =
        [SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE] &&
        [
          SPECIFICATION_TAG.CHANGE_REGISTRATION,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
          SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
        ].includes(specification)

      return (
        ([REASONS_FORM.AFFILIATE_FORM].includes(reason) &&
          [
            SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE,
            SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE,
            SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER_AFFILIATE,
            SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE,
          ].includes(subject)) ||
        CHANGE_REGISTRATION
      )
    },
    generate2FA(props) {
      const { fieldValue, reason } = props
      const { subjectSelect: subject, specificationSelect: specification } = fieldValue

      return (
        this.dataPrivacyForm(reason) ||
        this.buyerForm(reason, subject, specification) ||
        this.sparkleForm(reason, subject) ||
        this.producerOrCoProducerForm(reason, subject, specification) ||
        this.affiliateForm(reason, subject)
      )
    },
  },
}
