import { useRoute } from '@nuxtjs/composition-api'

const useOpenTicketFlow = () => {
  const route = useRoute()

  const match = (arr = [], key) => arr.length === 0 || arr.includes(route.value.query[key])

  /**
   * @param {Object} options - Options object
   * @param {string[]} [options.subject] - List of subjects (optional)
   * @param {string[]} [options.reason] - List of reasons (optional)
   * @param {string[]} [options.specification] - List of specifications (optional)
   *
   * @returns {boolean} Whether the current route matches the flow. If no options are provided, it will always return true.
   *
   * @example
   * const { inTheFlow } = useOpenTicketFlow()
   *
   * // ?subject=im-a-producer-and-i-need-help&reason=registration-and-modification-of-personal-data&specification=change-document-cnpj-to-cnpj
   * inTheFlow({
   *  subject: ['im-a-producer-and-i-need-help'],
   *  reason: ['registration-and-modification-of-personal-data'],
   *  specification: ['change-document-cnpj-to-cnpj']
   * }) // true (exact match)
   *
   * inTheFlow({
   *  subject: ['im-a-producer-and-i-need-help'],
   *  reason: ['registration-and-modification-of-personal-data']
   * }) // true for any specification
   *
   * inTheFlow({
   *  subject: ['im-a-producer-and-i-need-help']
   * }) // true for any reason and specification
   */
  const inTheFlow = options => {
    const { subject = [], reason = [], specification = [] } = options
    return match(subject, 'subject') && match(reason, 'reason') && match(specification, 'specification')
  }

  return { inTheFlow }
}

export { useOpenTicketFlow }
