import { computed } from '@nuxtjs/composition-api'
import has from 'lodash/has'


export default {
  __name: 'Button',
  props: {
  variant: {
    type: String,
    validator(value) {
      return [undefined, 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger', 'custom'].includes(
        value
      )
    },
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  rightIcon: {
    type: String,
    default: undefined,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  custom: {
    type: Object,
    validator(value) {
      return [
        has(value, 'backgroundColor'),
        has(value, 'borderColor'),
        has(value, 'color'),
        has(value, 'hover.backgroundColor'),
        has(value, 'hover.borderColor'),
        has(value, 'hover.color'),
        has(value, 'active.backgroundColor'),
        has(value, 'active.borderColor'),
        has(value, 'active.color'),
      ].some(Boolean)
    },
    default: undefined,
  },
},
  setup(__props) {

const props = __props;



const classes = computed(() => {
  return [
    'hot-button',
    { [`hot-button--${props.variant}`]: props.variant },
    { [`hot-button--${props.size}`]: props.size },
    { 'hot-button--rounded': props.rounded },
    { 'hot-button--loading': props.loading },
    { 'hot-button--block': props.block },
  ]
})

const customStyles = computed(() => ({
  ['--button-custom-bg']: props.custom?.backgroundColor,
  ['--button-custom-border-color']: props.custom?.borderColor,
  ['--button-custom-color']: props.custom?.color,
  ['--button-custom-hover-bg']: props.custom?.hover?.backgroundColor ?? props.custom?.backgroundColor,
  ['--button-custom-hover-border-color']: props.custom?.hover?.borderColor ?? props.custom?.borderColor,
  ['--button-custom-hover-color']: props.custom?.hover?.color ?? props.custom?.color,
  ['--button-custom-active-bg']: props.custom?.active?.backgroundColor ?? props.custom?.backgroundColor,
  ['--button-custom-active-border-color']: props.custom?.active?.borderColor ?? props.custom?.borderColor,
  ['--button-custom-active-color']: props.custom?.active?.color ?? props.custom?.color,
}))

return { __sfc: true,props, classes, customStyles }
}

}