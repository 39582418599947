import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=6ea960d2&scoped=true"
import script from "./Step4.vue?vue&type=script&setup=true&lang=js"
export * from "./Step4.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Step4.vue?vue&type=style&index=0&id=6ea960d2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ea960d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcStep4Title: require('/drone/src/components/Molecules/FormRequest/Step4/Step4-title.vue').default})
