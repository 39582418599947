const KEY_NAME = 'AI_USE_ORIGIN'

export default {
  data: function () {
    return {
      origins: {
        SEARCH: 'iaSearchBar',
        TICKET_SCREENING: 'iaRequestPage',
      },
    }
  },
  methods: {
    setAiUse(origin) {
      const prevOrigins = this.getAiInterventions()
      sessionStorage.setItem(KEY_NAME, JSON.stringify({ ...prevOrigins, [origin]: true }))
    },
    getAiInterventions() {
      const originsJson = sessionStorage.getItem(KEY_NAME)
      return originsJson ? JSON.parse(originsJson) : {}
    },
    clearIaUse() {
      sessionStorage.removeItem(KEY_NAME)
    },
  },
}
