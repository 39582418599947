export default {
  name: 'FieldFeedback',
  props: {
    msg: String,
    fieldName: String,
    maxCount: {
      type: Number,
      default: 0,
    },
  },
}
