export default {
  name: 'TextArea',
  props: {
    id: String,
    name: String,
    label: String,
    value: String,
    minlength: Number,
    maxlength: {
      type: Number,
      default: 4000,
    },
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    rows: Number,
    maxCount: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      alreadyFocused: false,
      isFocused: false,
    }
  },
  computed: {
    invalid: function () {
      return this.invalidRequired || this.invalidMaxCount
    },
    invalidMaxCount: function () {
      const invalid = this.maxCount && this.value && this.value.length > this.maxCount

      return invalid ? 'formValidation.maxCount' : false
    },
    invalidRequired: function () {
      const invalid = this.required && !this.value

      return invalid ? 'formValidation.required' : false
    },
    styleInvalidInput: function () {
      return this.invalid && !this.isFocused && (this.submitted || this.alreadyFocused) ? 'is-invalid' : ''
    },
  },
  methods: {
    blurFocused: function () {
      this.alreadyFocused = false
    },
    focus: function () {
      this.$refs.textArea.focus()
      this.alreadyFocused = true
    },
  },
}
