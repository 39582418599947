import { SPECIFICATION_TAG } from '~/enum/form/constants'

export const TOOLS = [
  {
    text: 'Webhook',
    specifications: [SPECIFICATION_TAG.EXTERNAL_PLATFORMS, SPECIFICATION_TAG.INTEGRATIONS],
  },
  {
    text: 'API',
    specifications: [SPECIFICATION_TAG.EXTERNAL_PLATFORMS, SPECIFICATION_TAG.INTEGRATIONS],
  },
  {
    text: 'Hotlinks',
    specifications: [SPECIFICATION_TAG.DISCLOSURE_LINKS],
  },
  {
    text: 'ticket.subject.specifications.options.dinamicPage',
    specifications: [SPECIFICATION_TAG.DISCLOSURE_LINKS],
  },
  {
    text: 'ticket.subject.specifications.options.trackingPixelTool',
    specifications: [SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE, SPECIFICATION_TAG.TRACKING_PIXEL],
  },
  {
    text: 'ticket.subject.specifications.options.parameters',
    specifications: [SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE, SPECIFICATION_TAG.TRACKING_PIXEL],
  },
  {
    text: 'Hotmart App',
    specifications: [SPECIFICATION_TAG.CONTENT_DELIVERY],
  },
  {
    text: 'Teachable',
    specifications: [SPECIFICATION_TAG.CONTENT_DELIVERY],
  },
  {
    text: 'Club',
    specifications: [
      SPECIFICATION_TAG.CONTENT_DELIVERY,
      SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA,
      SPECIFICATION_TAG.MEMBERS_AREA,
    ],
  },
  {
    text: 'ticket.subject.specifications.options.producerMembersArea',
    specifications: [SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA],
  },
  {
    text: 'ticket.subject.specifications.options.producerExternalMembersArea',
    specifications: [SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA],
  },
  {
    text: 'Kajabi',
    specifications: [SPECIFICATION_TAG.CONTENT_DELIVERY],
  },
  {
    text: 'ticket.subject.specifications.options.externalArea',
    specifications: [SPECIFICATION_TAG.CONTENT_DELIVERY],
  },
  {
    text: 'ticket.subject.specifications.options.moreFormatProducts',
    specifications: [SPECIFICATION_TAG.CONTENT_DELIVERY],
  },
  {
    text: 'ticket.subject.specifications.options.confirmationPage',
    specifications: [SPECIFICATION_TAG.SALES_PAGE],
  },
  {
    text: 'ticket.subject.specifications.options.internalPage',
    specifications: [SPECIFICATION_TAG.SALES_PAGE],
  },
  {
    text: 'ticket.subject.specifications.options.checkoutPage',
    specifications: [SPECIFICATION_TAG.SALES_PAGE],
  },
  {
    text: 'ticket.subject.specifications.options.externalPage',
    specifications: [SPECIFICATION_TAG.SALES_PAGE],
  },
  {
    text: 'ticket.subject.specifications.options.toolIntegrations',
    specifications: [SPECIFICATION_TAG.INTEGRATIONS],
  },
  {
    text: 'ticket.subject.specifications.options.klickPages',
    specifications: [SPECIFICATION_TAG.INTEGRATIONS],
  },
  {
    text: 'ticket.subject.specifications.options.collaboratorsCenter',
    specifications: [SPECIFICATION_TAG.EMPLOYEE_CENTER],
  },
  {
    text: 'ticket.subject.specifications.options.courseManagement',
    specifications: [SPECIFICATION_TAG.EMPLOYEE_CENTER],
  },
  {
    text: 'ticket.subject.specifications.options.protectContentEbook',
    specifications: [SPECIFICATION_TAG.PROTECT_CONTENT],
  },
  {
    text: 'ticket.subject.specifications.options.protectContentTools',
    specifications: [SPECIFICATION_TAG.PROTECT_CONTENT],
  },
  {
    text: 'ticket.subject.specifications.options.toolsHotmart',
    specifications: [SPECIFICATION_TAG.PRODUCER_LEADS],
  },
  {
    text: 'ticket.subject.specifications.options.toolsExternal',
    specifications: [SPECIFICATION_TAG.PRODUCER_LEADS],
  },
  {
    text: 'ticket.subject.specifications.options.freeRegistrationTool',
    specifications: [SPECIFICATION_TAG.IMPORTING_STUDENTS],
  },
  {
    text: 'ticket.subject.specifications.options.importingStudentsTool',
    specifications: [SPECIFICATION_TAG.IMPORTING_STUDENTS],
  },
  {
    text: 'ticket.complementaryData.fields.profile.affiliate',
    specifications: [SPECIFICATION_TAG.MEMBERS_AREA],
  },
  {
    text: 'Ebook',
    specifications: [SPECIFICATION_TAG.MEMBERS_AREA],
  },
  {
    text: 'ticket.subject.specifications.options.contactManagement',
    specifications: [SPECIFICATION_TAG.MEMBERS_AREA],
  },
  {
    text: 'ticket.subject.specifications.options.hotmartAnalytics',
    specifications: [SPECIFICATION_TAG.TRACKING_PIXEL],
  },
]
