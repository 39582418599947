import { mapActions } from 'vuex'
import { SUMMARY_FEEDBACK_CONTACT_US } from '~/enum/feedback'
import { NAME_PAGE } from '~/enum/pages'
import url from '~/mixins/url-structure'

export default {
  props: {
    origin: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: false,
    },
  },
  mixins: [url],
  methods: {
    ...mapActions('ticketFormAISummary', ['setShouldRenderAISummary']),
    ...mapActions('summaryScreeningFeedbackModal', ['setShouldOpenSummaryScreeningFeedbackModal']),
    handleProblemSolved() {
      const feedback = SUMMARY_FEEDBACK_CONTACT_US.FOUND_SOLUTION
      this.setShouldOpenSummaryScreeningFeedbackModal(false)
      this.sendCSATFeedback(feedback)
      this.answered = true
    },
    handleOpenRequest() {
      const feedback = SUMMARY_FEEDBACK_CONTACT_US.REQUEST_SERVICE
      this.setShouldOpenSummaryScreeningFeedbackModal(false)
      this.sendCSATFeedback(feedback)
      this.setShouldRenderAISummary(false)
    },
    handleOtherProblems() {
      const feedback = SUMMARY_FEEDBACK_CONTACT_US.HELP_OTHER_TOPIC
      this.setShouldOpenSummaryScreeningFeedbackModal(false)
      this.sendCSATFeedback(feedback)
      this.clearQueryAndResetNavigation()
    },
    clearQueryAndResetNavigation() {
      this.$router.replace({
        query: {},
      })
      setTimeout(() => {
        this.$router.go(NAME_PAGE.CONTACT_US)
      }, 100)
    },
    sendCSATFeedback(feedback) {
      this.$csat.sendFeedback({
        entityId: this.entityId,
        origin: this.origin,
        language: this.$route.params.locale,
        url: window.location.href,
        feedback: feedback,
      })
    },
  },
  data: () => ({
    answered: false,
    namePage: NAME_PAGE.CONTACT_US,
  }),
  mounted() {
    this.setShouldOpenSummaryScreeningFeedbackModal(true)
  },
}
