/**
 * Inspired by [fuzzy library](https://www.npmjs.com/package/fuzzy)
 */
export default class Fuzzy {
  static normalize(str) {
    return str
      .trim()
      .toLowerCase()
      .normalize('NFKD')
      .replace(/\p{Diacritic}/gu, '')
  }

  static match(pattern, str, opts = { pre: '', post: '', exactMatch: false }) {
    const { pre, post, exactMatch } = opts

    const normalizedPattern = (exactMatch && pattern) || Fuzzy.normalize(pattern)
    const normalizedStr = (exactMatch && str) || Fuzzy.normalize(str)

    let char = ''
    let patternIndex = 0
    let totalScore = 0
    let currentScore = 0

    const result = []
    for (let index = 0; index < str.length; index++) {
      char = str[index]
      if (normalizedStr[index] === normalizedPattern[patternIndex]) {
        char = pre + char + post
        patternIndex += 1
        currentScore += 1 + currentScore
      } else {
        currentScore = 0
      }
      totalScore += currentScore
      result[result.length] = char
    }

    if (patternIndex === normalizedPattern.length) {
      totalScore = normalizedStr === normalizedPattern ? Infinity : totalScore

      return { rendered: result.join(''), score: totalScore }
    }

    return null
  }

  /**
   * @param {string} pattern - Search pattern
   * @param {Array} arr - Target array
   * @param {{pre?: string, post?: string, exactMatch?: boolean, extract?: function}} opts - Options
   * @returns {{string: string, score: number, index: number, original: Object}[]} filtered array
   */
  static filter(pattern, arr, opts = { pre: '', post: '', exactMatch: false, extract: undefined }) {
    const { extract } = opts
    if (!arr || arr.length === 0) {
      return []
    }
    if (typeof pattern !== 'string') {
      return arr
    }

    return arr
      .reduce(function (prev, element, idx, arr) {
        let str = element
        if (extract) {
          str = extract(element)
        }
        const result = Fuzzy.match(pattern, str, opts)
        if (result != null) {
          prev[prev.length] = {
            string: result.rendered,
            score: result.score,
            index: idx,
            original: element,
          }
        }

        return prev
      }, [])
      .sort(function (a, b) {
        const compare = b.score - a.score
        if (compare) return compare

        return a.index - b.index
      })
  }
}
