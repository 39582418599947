import { computed, useContext } from '@nuxtjs/composition-api'
import { useOpenTicketFlow } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step3-title',
  setup(__props) {

const { $t, store } = useContext()
const { inTheFlow } = useOpenTicketFlow()

const shouldRenderChangeOwnership = computed(
  () => store.getters['ticketFormChangeOwnerShip/shouldRenderChangeOwnership']
)

const title = computed(() => {
  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug],
    })
  ) {
    return $t('components.stepTimeline.stepThreeCNPJ')
  }

  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_REGISTRATION.slug],
    })
  ) {
    return $t('components.stepTimeline.stepThreeCPF')
  }

  return $t('ticket.message.titleSecondStep')
})

return { __sfc: true,$t, store, inTheFlow, shouldRenderChangeOwnership, title }
}

}