import { computed, onMounted, ref } from '@nuxtjs/composition-api'


export default {
  __name: 'TicketModalBodyHelpMessage',
  props: {
  modal: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;



const helpMessage = ref(null)

const icon = computed(() => props.modal.help_message.icon)
const label = computed(() => props.modal.help_message.label)

onMounted(() => {
  const link = helpMessage.value?.querySelector('a')
  const element = link || helpMessage.value

  element.onclick = () => {
    props.modal.help_message.onClick?.()
    if (props.modal.dataLayer && props.modal.help_message.dataLayer) {
      dataLayer.push({
        event: 'custom_event',
        ...props.modal.dataLayer,
        ...props.modal.help_message.dataLayer,
      })
    }
  }
})

return { __sfc: true,props, helpMessage, icon, label }
}

}