import { isLoggedAndIsBuyer } from '~/enum/utils'

export default function () {
  const isBlipChat = this.$store.getters['chatbot/isBlipChat']

  return {
    shouldRender: !this.isVisitedArticle(360000649492) && isLoggedAndIsBuyer(this.$checkLogged(), isBlipChat),
    title: this.$t('chatbotModal.modal.title'),
    description: this.$t('chatbotModal.modal.description'),
    items: [
      {
        to: `${this.$i18n.locale}/article/360000649492`,
        target: '_blank',
        label: this.$t('chatbotModal.modal.contact'),
        dataLayer: {
          event_details: 'Click::FAQ',
        },
      },
      {
        shouldRender: /^(pt-br|en|es)$/.test(this.$i18n.locale),
        to: 'javascript:void(0)',
        onClick: this.openChat,
        label: this.$t('chatbotModal.modal.openchat'),
        dataLayer: {
          event_details: 'Click::Chatbot',
        },
      },
    ],
    dataLayer: {
      custom_event_name: 'show_modal_prodcut_questions',
      event_details: 'Open::ModalProductQuestions',
    },
  }
}
