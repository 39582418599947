import { computed, ref, useRoute, useRouter, useStore } from '@nuxtjs/composition-api'


export default {
  __name: 'ConfirmationModal',
  props: {
  isLoading: {
    type: Boolean,
    default: false,
  },
  check2fa: {
    type: Boolean,
    default: false,
  },
  buttonReceiveCode: {
    type: Boolean,
    default: undefined,
  },
  feedback2fa: {
    type: String,
    default: undefined,
  },
  countdown: {
    type: Number,
    default: undefined,
  },
  successMessage: {
    type: String,
    default: undefined,
  },
  sendDataForm: {
    type: Function,
    default: () => {},
  },
  sendTicket: {
    type: Function,
    default: () => {},
  },
},
  setup(__props, { expose }) {

const props = __props;



const value2fa = ref('')
const isOpen = ref(false)
const route = useRoute()
const router = useRouter()
const store = useStore()
const locale = useRoute().value.params.locale

function openModal() {
  isOpen.value = true
}

function closeModal() {
  isOpen.value = false
}

function resetNavigation() {
  return store.dispatch('ticketFormStepNavigation/resetNavigation')
}

function goToHome() {
  resetNavigation()
  router.push({ path: `/${locale}` })
}

expose({
  value2fa: computed(() => value2fa.value),
  isOpen: computed(() => isOpen.value),
  openModal,
  closeModal,
})

return { __sfc: true,props, value2fa, isOpen, route, router, store, locale, openModal, closeModal, resetNavigation, goToHome }
}

}