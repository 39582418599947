import { render, staticRenderFns } from "./TicketModalBodyHelpMessage.vue?vue&type=template&id=466150d4"
import script from "./TicketModalBodyHelpMessage.vue?vue&type=script&setup=true&lang=js"
export * from "./TicketModalBodyHelpMessage.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
