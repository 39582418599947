export default {
  props: {
    id: String,
    change: Function,
    removeFile: Function,
    isAttachmentsValid: Boolean,
    uploadFileLabel: String,
    attachments: [Array, Object],
    showLabelAttachment: Boolean,
    required: Boolean,
    showExample: { type: Boolean, default: false },
  },
  data: function () {
    return {
      alreadyFocused: false,
    }
  },
  computed: {
    invalid: function () {
      if (!this.isAttachmentsValid) {
        return 'formValidation.attachment'
      }

      let invalid = this.required && this.attachments.length <= 0
      if (this.attachments[this.id] || this.required) {
        invalid = this.required && (!this.attachments[this.id] || this.attachments[this.id]?.length <= 0)
      }

      return invalid ? 'formValidation.required' : false
    },
  },
  methods: {
    focus: function () {
      this.$refs.inputFile.focus()
      this.alreadyFocused = true
    },
  },
}
