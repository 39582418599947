/**
 * @typedef { 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds' } Units
 * @typedef { 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond' } Unit
 * @typedef { 'YYYY-MM-DD' } Format
 */

export default class $Date {
  /**
   * $Date constructor
   * @param { string | number | Date | $Date } value
   * @example
   * const date = new $Date('2023-06-20T19:53:20.123')
   */
  constructor(value) {
    if (value) {
      if (value instanceof $Date) {
        this.date = new Date(value.date)
      } else if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        this.date = new Date(`${value}T00:00:00.000`)
      } else {
        this.date = new Date(value)
      }
    } else {
      this.date = new Date()
    }
  }

  /**
   * Get or set milliseconds of date. If value is not passed, returns milliseconds of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.milliseconds(100) // Set milliseconds to 100 and return $Date
   * date.milliseconds() // Return 100 (number)
   */
  milliseconds(value) {
    if (isNaN(value)) return this.date.getMilliseconds()
    this.date.setMilliseconds(value)

    return this
  }

  /**
   * Get or set seconds of date. If value is not passed, returns seconds of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.seconds(10) // Set seconds to 10 and return $Date
   * date.seconds() // Return 10 (number)
   */
  seconds(value) {
    if (isNaN(value)) return this.date.getSeconds()
    this.date.setSeconds(value)

    return this
  }

  /**
   * Get or set minutes of date. If value is not passed, returns minutes of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.minutes(10) // Set minutes to 10 and return $Date
   * date.minutes() // Return 10 (number)
   */
  minutes(value) {
    if (isNaN(value)) return this.date.getMinutes()
    this.date.setMinutes(value)

    return this
  }

  /**
   * Get or set hours of date. If value is not passed, returns hours of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.hours(10) // Set hours to 10 and return $Date
   * date.hours() // Return 10 (number)
   */
  hours(value) {
    if (isNaN(value)) return this.date.getHours()
    this.date.setHours(value)

    return this
  }

  /**
   * Get or set day of date. If value is not passed, returns day of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.day(10) // Set day to 10 and return $Date
   * date.day() // Return 10 (number)
   */
  day(value) {
    if (isNaN(value)) return this.date.getDate()
    this.date.setDate(value)

    return this
  }

  /**
   * Get or set month of date. If value is not passed, returns month of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.month(10) // Set month to 10 and return $Date
   * date.month() // Return 10 (number)
   */
  month(value) {
    if (isNaN(value)) return this.date.getMonth()
    this.date.setMonth(value)

    return this
  }

  /**
   * Get or set year of date. If value is not passed, returns year of date.
   * @param { number } value
   * @returns { number | $Date }
   * @example
   * date.year(10) // Set year to 10 and return $Date
   * date.year() // Return 10 (number)
   */
  year(value) {
    if (isNaN(value)) return this.date.getFullYear()
    this.date.setFullYear(value)

    return this
  }

  /** Set date to start of unit
   * @param { Unit } unit
   * @returns { $Date } $Date
   * @throws { Error } Invalid unit
   * @example
   * const date = new $Date('2023-06-20T19:53:20.123')
   * date.startOf('second') // '2023-06-20T19:53:20.000'
   * date.startOf('minute') // '2023-06-20T19:53:00.000'
   * date.startOf('hour') // '2023-06-20T19:00:00.000'
   * date.startOf('day') // '2023-06-20T00:00:00.000'
   * date.startOf('month') // '2023-06-01T00:00:00.000'
   * date.startOf('year') // '2023-01-01T00:00:00.000'
   */
  startOf(unit) {
    switch (unit) {
      case 'year':
        return this.month(0).day(1).hours(0).minutes(0).seconds(0).milliseconds(0)
      case 'month':
        return this.day(1).hours(0).minutes(0).seconds(0).milliseconds(0)
      case 'day':
        return this.hours(0).minutes(0).seconds(0).milliseconds(0)
      case 'hour':
        return this.minutes(0).seconds(0).milliseconds(0)
      case 'minute':
        return this.seconds(0).milliseconds(0)
      case 'second':
        return this.milliseconds(0)
      default:
        throw new Error('Invalid unit')
    }
  }

  /** Format date
   * @param { Format } format
   * @returns { string } Formatted date
   * @throws { Error } Invalid format
   * @example
   * date.format('YYYY-MM-DD') // Return '2023-06-20'
   */
  format(format) {
    if (!this.isValid()) return 'Invalid Date'

    switch (format) {
      case 'YYYY-MM-DD':
        return this.date.toISOString().split('T')[0]
      default:
        throw new Error('Invalid format')
    }
  }

  /** Check if $Date date is before target date
   * @param { string | number | Date | $Date } date
   * @returns { boolean } True if date is after
   * @example
   * new $Date('2023-06-20').isBefore('2023-06-19') // Return false
   * new $Date('2023-06-20').isBefore('2023-06-20') // Return false
   * new $Date('2023-06-20').isBefore('2023-06-21') // Return true
   */
  isBefore(date) {
    return this.date.getTime() < new $Date(date).date.getTime()
  }

  /** Check if $Date date is same as target date
   * @param { string | number | Date | $Date } date
   * @returns { boolean } True if date is same
   * @example
   * new $Date('2023-06-20').isSame('2023-06-19') // Return false
   * new $Date('2023-06-20').isSame('2023-06-20') // Return true
   * new $Date('2023-06-20').isSame('2023-06-21') // Return false
   */
  isSame(date) {
    return this.date.getTime() === new $Date(date).date.getTime()
  }

  /** Check if $Date date is same or before target date
   * @param { string | number | Date | $Date } date
   * @returns { boolean } True if date is same or after
   * @example
   * new $Date('2023-06-20').isSameOrAfter('2023-06-19') // Return false
   * new $Date('2023-06-20').isSameOrAfter('2023-06-20') // Return true
   * new $Date('2023-06-20').isSameOrAfter('2023-06-21') // Return true
   */
  isSameOrBefore(date) {
    return this.isSame(date) || this.isBefore(date)
  }

  /** Check if $Date date is after target date
   * @param { string | number | Date | $Date } date
   * @returns { boolean } True if date is after
   * @example
   * new $Date('2023-06-20').isAfter('2023-06-19') // Return true
   * new $Date('2023-06-20').isAfter('2023-06-20') // Return false
   * new $Date('2023-06-20').isAfter('2023-06-21') // Return false
   */
  isAfter(date) {
    return this.date.getTime() > new $Date(date).date.getTime()
  }

  /** Check if $Date date is same or after target date
   * @param { string | number | Date | $Date } date
   * @returns { boolean } True if date is same or after
   * @example
   * new $Date('2023-06-20').isSameOrAfter('2023-06-19') // Return true
   * new $Date('2023-06-20').isSameOrAfter('2023-06-20') // Return true
   * new $Date('2023-06-20').isSameOrAfter('2023-06-21') // Return false
   */
  isSameOrAfter(date) {
    return this.isSame(date) || this.isAfter(date)
  }

  /** Check if $Date date is between two dates in any order (inclusive by default)
   * @param { string | number | Date | $Date } date1
   * @param { string | number | Date | $Date } date2
   * @param { boolean? } [inclusive = true] Include start and end dates
   * @returns { boolean } True if date is between
   * @example
   * new $Date('2023-06-20').isBetween('2023-06-19', '2023-06-21') // Return true
   * new $Date('2023-06-20').isBetween('2023-06-19', '2023-06-20') // Return true
   * new $Date('2023-06-20').isBetween('2023-06-19', '2023-06-20', false) // Return false
   */
  isBetween(date1, date2, inclusive = true) {
    let start = new $Date(date1)
    let end = new $Date(date2)

    if (start.isAfter(end)) {
      ;[start, end] = [end, start]
    }

    return inclusive ? this.isSameOrAfter(start) && this.isSameOrBefore(end) : this.isAfter(start) && this.isBefore(end)
  }

  /** Check if $Date date is valid
   * @returns { boolean } True if date is valid
   * @example
   * new $Date('2023-06-20').isValid() // Return true
   * new $Date('2023-06-32').isValid() // Return false
   */
  isValid() {
    return !Number.isNaN(new Date(this.date).getTime())
  }

  /**
   * Add value to $Date date
   * @param { number } value
   * @param { Unit | Units } units
   * @example
   * new $Date('2023-06-20').add(1, 'days') // Return $Date('2023-06-21')
   */
  add(value, units) {
    switch (true) {
      case /^year(s)?$/i.test(units):
        return this.year(this.year() + value)
      case /^month(s)?$/i.test(units):
        return this.month(this.month() + value)
      case /^day(s)?$/i.test(units):
        return this.day(this.day() + value)
      case /^hour(s)?$/i.test(units):
        return this.hours(this.hours() + value)
      case /^minute(s)?$/i.test(units):
        return this.minutes(this.minutes() + value)
      case /^second(s)?$/i.test(units):
        return this.seconds(this.seconds() + value)
      case /^millisecond(s)?$/i.test(units):
        return this.milliseconds(this.milliseconds() + value)
      default:
        throw new Error('Invalid unit')
    }
  }

  /**
   * Subtract value from $Date date
   * @param { number } value
   * @param { Unit | Units } units
   * @example
   * new $Date('2023-06-20').subtract(1, 'days') // Return $Date('2023-06-19')
   */
  subtract(value, units) {
    return this.add(-value, units)
  }

  /**
   * Clone $Date date and return new $Date instance
   * @returns { $Date } New $Date instance
   * @example
   * const date = new $Date('2023-06-20')
   * const clonedDate = date.clone()
   * clonedDate.add(1, 'days').format('YYYY-MM-DD') // Return '2023-06-21'
   * date.format('YYYY-MM-DD') // Return '2023-06-20'\
   */
  clone() {
    return new $Date(this.date)
  }
}
