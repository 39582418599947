import { computed } from '@nuxtjs/composition-api'


export default {
  __name: 'TicketModalFooter',
  props: {
  modal: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;



const actions = computed(() => props.modal.actions)

return { __sfc: true,props, actions }
}

}