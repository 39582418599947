import { computed, ref, useContext } from '@nuxtjs/composition-api'
import { useMask, useOpenTicketFlow, useValidation } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step3-cpf',
  setup(__props, { expose }) {

const { $t, store } = useContext()
const { inTheFlow } = useOpenTicketFlow()
const validate = useValidation()
const mask = useMask()

const inputRef = ref(null)
const value = ref('')

const shouldRenderChangeOwnership = computed(
  () => store.getters['ticketFormChangeOwnerShip/shouldRenderChangeOwnership']
)

const maskCpf = computed(() => (shouldRenderChangeOwnership.value ? mask.cpf : undefined))
const validateCpf = computed(() => (shouldRenderChangeOwnership.value ? validate.cpf : undefined))

const shouldRender = computed(
  () =>
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      specification: [
        SPECIFICATION.CHANGE_REGISTRATION.slug,
        SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF.slug,
        SPECIFICATION.CANT_MAKE_PURCHASE.slug,
      ],
    }) ||
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      reason: [SUBJECT.CANT_MAKE_PURCHASE.slug, SUBJECT.BUYER_PHYSICAL_PRODUCTS.slug],
    }) ||
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      specification: [
        SPECIFICATION.CHANGING_HOTMART_EMAIL.slug,
        SPECIFICATION.CHANGE_REQUEST_REASON.slug,
        SPECIFICATION.OTHER_CHANGES.slug,
        SPECIFICATION.PIX.slug,
      ],
    })
)

const required = computed(
  () =>
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP, SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      specification: [SPECIFICATION.CHANGE_REGISTRATION.slug, SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF.slug],
    }) || inTheFlow({ reason: [SUBJECT.CANT_MAKE_PURCHASE.slug, SUBJECT.BUYER_PHYSICAL_PRODUCTS.slug] })
)

const label = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      reason: [SUBJECT.BUYER_PHYSICAL_PRODUCTS.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.documentPrincipal.title`)
  }
  if (
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      reason: [SUBJECT.CANT_MAKE_PURCHASE.slug],
    }) ||
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP, SLUG.IM_A_PRODUCER_AND_I_NEED_HELP],
      reason: [SUBJECT.PAYMENT_INFORMATION.slug, SUBJECT.HELP_MY_STUDENTS.slug],
      specification: [SPECIFICATION.PIX.slug, SPECIFICATION.CANT_MAKE_PURCHASE.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.documentPrincipal_v2.title`)
  }

  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_REGISTRATION.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.accountCPF.title_v2`)
  }

  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      specification: [SPECIFICATION.CHANGE_REGISTRATION.slug, SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.accountCPF.title`)
  }

  return $t(`ticket.complementaryData.fields.CPF.title`)
})

const placeholder = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      reason: [SUBJECT.BUYER_PHYSICAL_PRODUCTS.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.documentPrincipal.placeholder`)
  }
  if (
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP],
      reason: [SUBJECT.CANT_MAKE_PURCHASE.slug],
    }) ||
    inTheFlow({
      subject: [SLUG.BOUGHT_AND_NEED_HELP, SLUG.IM_A_PRODUCER_AND_I_NEED_HELP],
      reason: [SUBJECT.PAYMENT_INFORMATION.slug, SUBJECT.HELP_MY_STUDENTS.slug],
      specification: [SPECIFICATION.PIX.slug, SPECIFICATION.CANT_MAKE_PURCHASE.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.documentPrincipal_v2.placeholder`)
  }

  if (
    shouldRenderChangeOwnership.value &&
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_REGISTRATION.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.accountCPF.placeholder_v2`)
  }

  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      specification: [SPECIFICATION.CHANGE_REGISTRATION.slug, SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF.slug],
    })
  ) {
    return $t(`ticket.complementaryData.fields.accountCPF.placeholder`)
  }

  return $t(`ticket.complementaryData.fields.CPF.placeholder`)
})

function updateFormData(formData) {
  if (value.value) formData.set('cpf', value.value)
}

expose({
  invalid: computed(() => inputRef.value?.invalid),
  required: computed(() => inputRef.value?.required),
  focus: () => inputRef.value?.focus(),
  updateFormData,
  value,
})

return { __sfc: true,$t, store, inTheFlow, validate, mask, inputRef, value, shouldRenderChangeOwnership, maskCpf, validateCpf, shouldRender, required, label, placeholder, updateFormData }
}

}