export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const hotModal = this.$refs.hotModal

    hotModal.addEventListener('close', () => {
      this.$emit('close')
    })
    hotModal.addEventListener('open', () => {
      this.$emit('open')

      const closeButton = hotModal._modalHeader?._closeIcon
      closeButton?.addEventListener('click', () => {
        this.$emit('click-close-button')
      })

      const backdrop = hotModal._backdrop
      backdrop?.addEventListener('click', () => {
        this.$emit('click-backdrop')
      })
    })
  },
}
