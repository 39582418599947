import { render, staticRenderFns } from "./Step3-title.vue?vue&type=template&id=c2926304"
import script from "./Step3-title.vue?vue&type=script&setup=true&lang=js"
export * from "./Step3-title.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default})
