import * as cnpj from '@fnando/cnpj'
import * as cpf from '@fnando/cpf'
import { useContext } from '@nuxtjs/composition-api'

function useValidation() {
  const { $t } = useContext()

  return {
    cpf: value => {
      if (!cpf.isValid(value)) {
        return $t('formValidation.CPF')
      }
      return false
    },
    cnpj: value => {
      if (!cnpj.isValid(value)) {
        if (cnpj.strip(value).length !== 14) return $t('formValidation.CNPJ.minLength')
        return $t('formValidation.CNPJ.invalid')
      }
      return false
    },
  }
}

export { useValidation }
