import * as cnpj from '@fnando/cnpj'
import * as cpf from '@fnando/cpf'

function useMask() {
  return {
    cpf: cpf.format,
    cnpj: cnpj.format,
  }
}

export { useMask }
