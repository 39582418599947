import { formOptions } from '@/enum/options'
import { head } from '@/utils/head'
import { alternatives, availableLocale } from '@/utils/sharedHelpers'
import { mapActions, mapGetters } from 'vuex'
import { SLUG } from '~/enum/form/constants'

export default {
  middleware({ query, store, from }) {
    const { subject } = query
    if (subject && !from && subject !== SLUG.HOTMART_EXTENSIONS) {
      const currentStepIndex = store.getters['ticketFormStepNavigation/currentStepIndex']
      if (currentStepIndex === 0) store.dispatch('ticketFormStepNavigation/moveToNextStep')
    }
  },
  validate({ params, query }) {
    const { locale } = params
    const { subject: subjectSlug, reason: reasonSlug, specification: specificationSlug } = query

    const subjectOption = formOptions.find(subject => subject.slug === subjectSlug)
    const reasonOptions = subjectOption?.subjects || []
    const specificationOptions = reasonOptions
      .filter(reason => reason.slug === reasonSlug)
      .flatMap(reason => reason.specifications)
      .filter(Boolean)

    const isValidLocale = availableLocale(locale)
    const isValidSubject = !subjectSlug || Boolean(subjectOption)
    const isValidReason = !reasonSlug || reasonOptions.flatMap(reason => reason.slug).includes(reasonSlug)
    const isValidSpecification =
      !specificationSlug ||
      specificationOptions.flatMap(specification => specification.slug).includes(specificationSlug)

    return isValidLocale && isValidSubject && isValidReason && isValidSpecification
  },
  computed: {
    ...mapGetters('casUser', ['userToken']),
  },
  methods: {
    ...mapActions('ticketFormStepNavigation', ['resetNavigation']),
  },
  mounted() {
    dataLayer.push({
      event: 'pageview',
      section: 'Help Center',
      subsection1: 'ContactUs',
      subsection2: window.location.href,
      language: this.$i18n.locale,
      logged: this.$checkLogged(),
    })
  },
  beforeDestroy() {
    this.resetNavigation()
  },
  head() {
    const { fullPath } = this.$route
    const lang = this.$i18n.locale
    const canonical = `${this.$config.baseUrl}${fullPath}`
    const title = `${this.$t('ticket.sendATicket')} - ${this.$t('seo.title')}`
    const alternates = alternatives(fullPath)
    const robots = { hid: 'robots', name: 'robots', content: 'noindex, nofollow' }

    return head({
      lang,
      canonical,
      alternates,
      title,
      robots,
    })
  },
}
