import { render, staticRenderFns } from "./TicketModalFooter.vue?vue&type=template&id=c1705f26"
import script from "./TicketModalFooter.vue?vue&type=script&setup=true&lang=js"
export * from "./TicketModalFooter.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcButton: require('/drone/src/components/Atoms/Button/Button.vue').default})
