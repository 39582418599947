import { isLoggedAndIsBuyer } from '~/enum/utils'

export default function () {
  const isBlipChat = this.$store.getters['chatbot/isBlipChat']

  return {
    shouldRender:
      /^(pt-br|en|es)$/.test(this.$i18n.locale) &&
      !this.isVisitedArticle(115002183968) &&
      isLoggedAndIsBuyer(this.$checkLogged(), isBlipChat),
    title: this.$t('ticketModal.cancelSubscription.title'),
    description: this.$t('ticketModal.cancelSubscription.description'),
    items: [
      {
        to: 'javascript:void(0)',
        onClick: this.openChat,
        label: this.$t('ticketModal.cancelSubscription.links.openchat'),
        dataLayer: {
          event_details: 'Click::Chatbot',
        },
      },
    ],
    help_message: {
      label: this.$t('ticketModal.cancelSubscription.helpMessage', {
        link: `${this.$i18n.locale}/article/115002183968?itm_medium=internal&itm_source=help-center&itm_campaign=new-request_modal_cancel_subscription`,
      }),
      onClick: () => {}, // This is a noop function
      dataLayer: {
        event_details: 'Click::FAQ',
      },
    },
    dataLayer: {
      custom_event_name: 'show_modal_cancel_subscription',
      event_details: 'Open::ModalCancelSubscription',
    },
  }
}
