export const ARTICLE_FEEDBACK = {
  NEGATIVE: 'NEGATIVO',
  POSITIVE: 'POSITIVO',
}

export const SUMMARY_FEEDBACK = {
  DID_HELP: 'A_RESPOSTA_ME_AJUDOU',
  DID_NOT_HELP: 'A_INFORMACAO_NAO_AJUDOU',
  DID_NOT_UNDERSTAND_ME: 'NAO_ATENDEU_MINHA_DUVIDA',
}

export const SUMMARY_FEEDBACK_CONTACT_US = {
  FOUND_SOLUTION: 'ENCONTREI_SOLUCAO',
  REQUEST_SERVICE: 'SOLICITAR_ATENDIMENTO',
  HELP_OTHER_TOPIC: 'AJUDA_OUTRO_TEMA',
}

export const SUMMARY_SCREENING_FEEDBACK_MODAL = {
  YES: 'ENCONTREI_SOLUCAO',
  NO: 'NAO_ENCONTREI_SOLUCAO',
  SUBMIT_A_REQUEST: 'SOLICITAR_ATENDIMENTO',
  HELP_WITH_ANOTHER_SUBJECT: 'AJUDA_OUTRO_TEMA',
}
