import { mapActions, mapGetters } from 'vuex'
import { REASONS_FORM, SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { formOptions } from '~/enum/options'
import chatbot from '~/mixins/chatbot'
import header from '~/mixins/header'
import mediaQuery from '~/mixins/media-query'

const DATA_PRIVACY_FORM = formOptions.find(({ reason }) => reason === REASONS_FORM.DATA_PRIVACY_FORM)

export default {
  mixins: [header, mediaQuery, chatbot],

  data: function () {
    let reason = ''

    formOptions.map(key => {
      if ([DATA_PRIVACY_FORM.slug].includes(this.$route.params.slug)) {
        reason = DATA_PRIVACY_FORM.reason
      } else {
        if ([key.slug].includes(this.$route.query.subject)) {
          return (reason = key.reason)
        }
      }
    })

    return {
      stepName: '',
      reason,
      subject: '',
      specification: '',
      isCenterContainer: false,
      isLastStep: false,
      isExtensionReason: false,
    }
  },
  watch: {
    currentStepIndex: {
      handler: function (currentStepIndex) {
        this.isCenterContainer = currentStepIndex > 0
        this.isLastStep = false
        const hideChatBot = currentStepIndex === 2 || currentStepIndex === 3
        this.setVisible(!hideChatBot)

        if (this.isMobile) {
          const showButtonOnlyTwoStep = currentStepIndex === 2 && !this.hasFieldsInStep('#ticketFormStep3')
          const showButtonOnlyThreeStep = currentStepIndex === 3 && this.hasFieldsInStep('#ticketFormStep3')
          const showButtonOnlyFourStep = currentStepIndex === 4 && this.hasFieldsInStep('#ticketFormStep4')

          if (showButtonOnlyTwoStep || showButtonOnlyThreeStep || showButtonOnlyFourStep) {
            this.isLastStep = true
            window.scrollTo(0, 0)
          }
        }
      },
      immediate: true,
    },
    '$route.query': {
      handler: function () {
        this.initAISummary()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.setVisible(true)
  },
  computed: {
    ...mapGetters('ticketFormStepNavigation', [
      'currentStepIndex',
      'hasMissingRequiredFieldsInStep',
      'hasFieldsInStep',
      'isValidStep',
    ]),
    ...mapGetters('ticketFormAISummary', ['shouldRenderAISummary']),
    ...mapGetters('ticketFormChangeOwnerShip', ['shouldRenderChangeOwnership', 'changeOwnershipPersona']),
    shouldRenderInSteps: function () {
      return ![SLUG.PRIVACY_POLICY].includes(this.$route.params.slug)
    },
    description: function () {
      if (this.currentStepIndex >= 3 && this.shouldRenderChangeOwnership) return this.$t(`ticket.steps.3.description`)
      return this.$t(`ticket.steps.${this.currentStepIndex}.description`)
    },
    title: function () {
      if (this.currentStepIndex >= 3 && this.shouldRenderChangeOwnership) return this.$t(`ticket.steps.3.title`)
      return this.$t(`ticket.steps.${this.currentStepIndex}.title`)
    },
    steps: function () {
      return [
        {
          selector: '#ticketFormStep0',
          label: '',
          hidden: true,
          navigation: [
            {
              label: 'components.nextStep.button.start',
              className: 'position-mobile',
              onClick: () => {
                dataLayer.push({
                  event: 'custom_event',
                  custom_event_name: 'enter_request',
                  event_details: 'Open::FormTicket',
                })
                this.checkNavigation()
                !this.isExtensionReason && this.moveToNextStep()
              },
              rightIcon: 'arrowLeft',
              disabled: !this.$route.query.subject,
            },
          ],
        },
        {
          selector: '#ticketFormStep1',
          label: 'components.stepTimeline.stepOne',
          hidden: false,
          navigation: [
            {
              label: 'components.nextStep.button.back',
              onClick: this.moveToPreviousStep,
              leftIcon: 'arrowRight',
              className: 'hot-button--secondary',
            },
            {
              label: 'components.nextStep.button.advance',
              onClick: () => {
                this.validateAndMoveToNextStep('#ticketFormStep1')
              },
              rightIcon: 'arrowLeft',
              disabled: this.hasMissingRequiredFieldsInStep('#ticketFormStep1'),
            },
          ],
        },
        {
          selector: '#ticketFormStep2',
          label: 'components.stepTimeline.stepTwo',
          hidden: false,
          navigation: [
            {
              label: 'components.nextStep.button.back',
              onClick: this.moveToPreviousStep,
              leftIcon: 'arrowRight',
              className: 'hot-button--secondary',
            },
            {
              hidden: !this.hasFieldsInStep('#ticketFormStep3'),
              label: 'components.nextStep.button.advance',
              onClick: () => {
                this.validateAndMoveToNextStep('#ticketFormStep2')
              },
              rightIcon: 'arrowLeft',
              disabled: this.hasMissingRequiredFieldsInStep('#ticketFormStep2'),
            },
          ],
        },
        {
          selector: '#ticketFormStep3',
          label: (() => {
            if (this.shouldRenderAISummary && this.currentStepIndex === 3) {
              return 'components.stepTimeline.helpForYou'
            }
            if (this.shouldRenderChangeOwnership) {
              switch (this.$route.query?.specification) {
                case SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug:
                  return 'components.stepTimeline.stepThreeCNPJ'
                case SPECIFICATION.CHANGE_REGISTRATION.slug:
                  return 'components.stepTimeline.stepThreeCPF'
                case SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug:
                  return 'components.stepTimeline.stepThreeCNPJ'
              }
            }
            return 'components.stepTimeline.stepThree'
          })(),
          hidden: !this.hasFieldsInStep('#ticketFormStep3'),
          navigation: [
            {
              label: 'components.nextStep.button.back',
              onClick: async () => {
                if (await this.openSummaryScreeningFeedbackModal()) return
                this.moveToPreviousStep()
              },
              leftIcon: 'arrowRight',
              className: 'hot-button--secondary',
            },
            {
              hidden: !this.shouldRenderChangeOwnership,
              label: 'components.nextStep.button.advance',
              onClick: () => {
                this.validateAndMoveToNextStep('#ticketFormStep3')
                if (this.isValidStep('#ticketFormStep3')) {
                  this.sendChangeOwnershipAnalytic()
                }
              },
              rightIcon: 'arrowLeft',
              disabled: this.hasMissingRequiredFieldsInStep('#ticketFormStep3'),
            },
          ],
        },
        {
          selector: '#ticketFormStep4',
          label: (() => {
            switch (this.$route.query?.specification) {
              case SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug:
                return 'components.stepTimeline.stepFourCNPJ'
              case SPECIFICATION.CHANGE_REGISTRATION.slug:
                return 'components.stepTimeline.stepFourCNPJ'
              case SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug:
                return 'components.stepTimeline.stepFourCPF'
            }
          })(),
          hidden: !this.shouldRenderChangeOwnership,
          navigation: [
            {
              label: 'components.nextStep.button.back',
              onClick: this.moveToPreviousStep,
              leftIcon: 'arrowRight',
              className: 'hot-button--secondary',
            },
          ],
        },
      ]
    },
    renderReason: function () {
      return ![DATA_PRIVACY_FORM.reason].includes(this.reason)
    },
    renderFormRequest: function () {
      return Boolean(this.reason)
    },
  },
  methods: {
    ...mapActions('ticketFormStepNavigation', ['moveToNextStep', 'validateAndMoveToNextStep', 'moveToPreviousStep']),
    ...mapActions('ticketFormAISummary', ['initAISummary']),
    ...mapActions('summaryScreeningFeedbackModal', ['openSummaryScreeningFeedbackModal']),
    checkNavigation() {
      this.isExtensionReason = this.reason === REASONS_FORM.EXTENSIONS_FORM
      const currentLang = this.$route.params.locale
      const langMap = {
        'pt-br': 'pt-br',
        es: 'es-mx',
        en: 'en-us',
        default: 'en-us',
      }
      const lang = langMap[currentLang] || langMap['default']
      if (this.isExtensionReason) {
        const extensionsURL = this.$config.extensionsURL.replace('[lang]', lang)
        return window.open(extensionsURL, '_blank', 'noopener')
      }
    },
    updateReason(newReason) {
      const { subject } = this.$route.query
      const { slug } = formOptions.find(({ reason }) => reason === newReason)
      this.reason = newReason

      if (subject !== slug) {
        return this.$router.replace({
          query: { subject: slug },
        })
      }
    },
    sendChangeOwnershipAnalytic() {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'change_ownership',
        event_details: `open::CurrentDocsCompany${this.changeOwnershipPersona}`,
      })
    },
  },
}
