export default function () {
  return {
    shouldRender: /^(pt-br|en|es)$/.test(this.$i18n.locale) && !this.isVisitedArticle(360029727112),
    title: this.$t('ticketModal.changeRegistrationInformation.title'),
    description: this.$t('ticketModal.changeRegistrationInformation.description'),
    items: [
      {
        to: `${this.$i18n.locale}/article/360029727112?utm_source=modal-ticket&utm_medium=change-buyer-email&utm_campaign=helpcenter`,
        label: this.$t('ticketModal.changeRegistrationInformation.links.change-email'),
        dataLayer: {
          event_details: 'Click::FAQ',
        },
      },
    ],
    dataLayer: {
      custom_event_name: 'show_modal_change_student_email',
      event_details: 'Open::ModalChangeStudentEmail',
    },
  }
}
