import { computed, ref, useContext } from '@nuxtjs/composition-api'
import { useOpenTicketFlow } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step4',
  setup(__props, { expose }) {

const { inTheFlow } = useOpenTicketFlow()
const componentRefs = ref([])
const { store } = useContext()

const newPartnersForm = computed(
  () => componentRefs.value.find(component => component.$el.id === 'newPartnersForm')?.value
)

const shouldRenderChangeOwnership = computed(
  () => store.getters['ticketFormChangeOwnerShip/shouldRenderChangeOwnership']
)

const components = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_REGISTRATION.slug],
    })
  ) {
    return ['newCnpj', 'newDocumentFiles', 'newPartnersForm']
  }

  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug],
    })
  ) {
    return [
      'newCpf',
      'userNewEmail',
      'frontDocumentFiles',
      'backDocumentFiles',
      'selfieDocumentFiles',
      'proofResidenceFiles',
    ]
  }

  return []
})

expose({
  invalid: computed(() => componentRefs.value.some(component => component.invalid)),
  required: computed(() => componentRefs.value.some(component => component.required)),
  focus: () => componentRefs.value.filter(component => component.invalid)[0]?.focus(),
  updateFormData: formData => componentRefs.value.forEach(component => component.updateFormData(formData)),
  value: computed(() => componentRefs.value.map(component => component.value)),
  canSubmit: computed(() =>
    newPartnersForm.value
      ? !!newPartnersForm.value.length && newPartnersForm.value.every(partner => partner.state === 'confirmed')
      : true
  ),
})

return { __sfc: true,inTheFlow, componentRefs, store, newPartnersForm, shouldRenderChangeOwnership, components }
}

}