export default function () {
  const { locale } = this.$i18n
  return {
    shouldRender: !this.shouldRenderAISummary,
    title: this.$t('physicalProductsRefund.modal.title'),
    description: this.$t('physicalProductsRefund.modal.description'),
    onClose: this.closeModal,
    items: [
      {
        to: `https://refund.hotmart.com/?lang=${locale}&_gl=1%2auct7id%2a_ga%2aNjMzMDU5NTAxLjE3MDI5MjU0ODA.%2a_ga_GQH2V1F11Q%2aMTcxMTAyOTA4NS45Ni4wLjE3MTEwMjkwODUuNjAuMC4w`,
        target: '_blank',
        label: this.$t('physicalProductsRefund.modal.refund'),
      },
    ],
    help_message: {
      label: this.$t('physicalProductsRefund.modal.faq', {
        link: `${this.$i18n.locale}/article/${this.$config.requestRefundArticleId}?utm_source=modal-ticket&utm_medium=track-refund`,
      }),
      onClick: () => {}, // This is a noop function
    },
  }
}
