import { mapActions, mapGetters } from 'vuex'
import { SUMMARY_SCREENING_FEEDBACK_MODAL } from '~/enum/feedback'

export default {
  props: {
    origin: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: false,
    },
  },
  data: () => ({
    feedback: null,
    timeout: null,
  }),
  computed: {
    ...mapGetters('summaryScreeningFeedbackModal', ['isOpenSummaryScreeningFeedbackModal']),
    modal() {
      switch (this.feedback) {
        case null:
          return {
            title: this.$t('summaryScreeningFeedbackModal.wasThisInformationHelpful.title'),
            body: 'hcWasThisInformationHelpful',
          }
        case SUMMARY_SCREENING_FEEDBACK_MODAL.YES:
          return {
            title: this.$t('summaryScreeningFeedbackModal.thankYouForYourFeedback.title'),
            body: 'hcThankYouForYourFeedback',
          }
        default:
          return {
            title: this.$t('summaryScreeningFeedbackModal.howDoYouWantToContinue.title'),
            body: 'hcHowDoYouWantToContinue',
          }
      }
    },
  },
  methods: {
    ...mapActions('summaryScreeningFeedbackModal', [
      'openSummaryScreeningFeedbackModal',
      'setIsOpenSummaryScreeningFeedbackModal',
      'setShouldOpenSummaryScreeningFeedbackModal',
    ]),
    ...mapActions('ticketFormAISummary', ['setShouldRenderAISummary']),
    goToContactUs() {
      window.location.search = ''
    },
    goToHome() {
      this.$router.push({ path: `/${this.$i18n.locale}` })
    },
    async handleBeforeUnloadEvent(e) {
      if (await this.openSummaryScreeningFeedbackModal()) e.preventDefault()
    },
    close() {
      clearTimeout(this.timeout)
      this.setIsOpenSummaryScreeningFeedbackModal(false)
      this.setShouldOpenSummaryScreeningFeedbackModal(false)
      window.removeEventListener('beforeunload', this.handleBeforeUnloadEvent)
    },
    handleUserCloseModal() {
      if (!this.isOpenSummaryScreeningFeedbackModal) return
      this.close()
      if (this.feedback === SUMMARY_SCREENING_FEEDBACK_MODAL.YES) return this.goToHome()
      this.goToContactUs()
    },
    handleYes() {
      this.timeout = setTimeout(() => {
        this.close()
        this.goToHome()
      }, 10000)
    },
    handleSumbitARequest() {
      this.close()
      this.setShouldRenderAISummary(false)
    },
    handleHelpWithAnotherSubject() {
      this.close()
      this.goToContactUs()
    },
    handleFeedback(feedback) {
      this.feedback = feedback
      ;({
        [SUMMARY_SCREENING_FEEDBACK_MODAL.YES]: this.handleYes,
        [SUMMARY_SCREENING_FEEDBACK_MODAL.SUBMIT_A_REQUEST]: this.handleSumbitARequest,
        [SUMMARY_SCREENING_FEEDBACK_MODAL.HELP_WITH_ANOTHER_SUBJECT]: this.handleHelpWithAnotherSubject,
      })[feedback]?.()
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnloadEvent)
  },
  beforeDestroy() {
    this.close()
  },
}
