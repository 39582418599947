export default {
  methods: {
    formatSummaryLinks(summary) {
      summary.matchAll('<a href.*?>')?.forEach?.(({ 0: link }) => {
        if (/target=".*"/gi.test(link)) return

        const newLink = link.replace('>', ' target="_blank">')
        summary = summary.replace(link, newLink)
      })

      return summary
    },
  },
}
