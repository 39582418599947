export default {
  props: {
    messages: {
      type: Array,
      default: () => ['loading.prepareAnwser', 'loading.wontTakeLong', 'loading.ready'],
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },
  data: () => ({
    index: 0,
    intervalId: null,
  }),
  computed: {
    length() {
      return this.messages.length
    },
    currentMessage() {
      return this.messages[((this.index % this.length) + this.length) % this.length]
    },
  },
  watch: {
    interval: {
      handler(value) {
        if (this.intervalId) {
          clearInterval(this.intervalId)
        }
        this.intervalId = setInterval(() => {
          this.index++
        }, value)
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
}
