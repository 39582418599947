import { computed, useContext } from '@nuxtjs/composition-api'
import { useOpenTicketFlow } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step4-title',
  setup(__props) {

const { inTheFlow } = useOpenTicketFlow()
const { $t } = useContext()

const title = computed(() => {
  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug, SPECIFICATION.CHANGE_REGISTRATION.slug],
    })
  ) {
    return $t('components.stepTimeline.stepFourCNPJ')
  }
  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug],
    })
  ) {
    return $t('components.stepTimeline.stepFourCPF')
  }
})

return { __sfc: true,inTheFlow, $t, title }
}

}