
export default {
  name: 'InfoBox',
  props: {
    error: {
      type: String,
      default: '',
    },
    typeInfo: {
      type: String,
      default: '',
    },
  },
  computed: {
    errorMsg: function () {
      const title = this.$t(`error-messages.${this.error}`, this.translationParams)
      const subtitleMsg = `client-error-message.${this.error}`
      const customSubtitle = this.$t(subtitleMsg, this.translationParams)
      const isDefaultError = subtitleMsg === customSubtitle
      const subtitle = isDefaultError ? this.$t(`client-error-message.default`) : customSubtitle
      return { title, subtitle }
    },
  },
}
