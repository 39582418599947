import { render, staticRenderFns } from "./TagGroup.vue?vue&type=template&id=b11eee4c&scoped=true"
import script from "./TagGroup.vue?vue&type=script&setup=true&lang=js"
export * from "./TagGroup.vue?vue&type=script&setup=true&lang=js"
import style0 from "./TagGroup.scss?vue&type=style&index=0&id=b11eee4c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b11eee4c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcLabel: require('/drone/src/components/Atoms/Label/Label.vue').default})
