export default {
  props: {
    id: String,
    name: String,
    validate: Function,
    label: String,
    type: String,
    required: Boolean,
    disabled: Boolean,
    values: Array,
    limit: {
      type: Number,
      default: 10,
    },
    onAdd: Function,
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      alreadyFocused: false,
      isFocused: false,
      value: false,
    }
  },
  computed: {
    invalid: function () {
      return this.invalidRequired || this.dontPassValidateMethod
    },
    invalidRequired: function () {
      const invalid = this.required && this.values.length <= 0

      return invalid ? 'formValidation.required' : false
    },
    dontPassValidateMethod: function () {
      return this.validate ? this.validate(this.value) : false
    },
    styleInvalidInput: function () {
      return this.invalid && this.alreadyFocused ? 'is-invalid' : ''
    },
    limitExceeds: function () {
      return this.values.length >= this.limit
    },
  },
  methods: {
    onInput: function (value) {
      this.value = value
    },
    blurFocused: function () {
      this.alreadyFocused = false
    },
    focus: function () {
      this.$refs.input.focus()
      this.alreadyFocused = true
    },
    reset: function () {
      this.isFocused = false
      this.alreadyFocused = false
    },
    addItem: function () {
      if (this.values.length >= this.limit) {
        return
      }

      this.onAdd(this.$refs.input.value)
      this.$refs.input.value = ''
      this.value = ''
    },
    removeItem: function (index) {
      this.values.splice(index, 1)
    },
  },
}
