import defaultsDeep from 'lodash/defaultsDeep'
import { SLUG } from '../constants'
import { SPECIFICATION } from '../specification'
import { SUBJECT } from '../subject'
import CANCEL_SUBSCRIPTION from './variations/CANCEL_SUBSCRIPTION'
import CERTIFICATE_EMISSION from './variations/CERTIFICATE_EMISSION'
import CHANGE_DOCUMENT_CNPJ_TO_CNPJ from './variations/CHANGE_DOCUMENT_CNPJ_TO_CNPJ'
import CHANGE_DOCUMENT_CNPJ_TO_CPF from './variations/CHANGE_DOCUMENT_CNPJ_TO_CPF'
import CHANGE_IN_REGISTRATION_INFORMATION from './variations/CHANGE_IN_REGISTRATION_INFORMATION'
import CHANGE_REGISTRATION from './variations/CHANGE_REGISTRATION'
import CHANGE_REQUEST_REASON from './variations/CHANGE_REQUEST_REASON'
import PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS from './variations/PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS'
import PRODUCT_QUESTIONS from './variations/PRODUCT_QUESTIONS'
import REQUEST_REFUND from './variations/REQUEST_REFUND'
import TRACK_REFUND from './variations/TRACK_REFUND'
import TROUBLE_ACESSING_CONTENT from './variations/TROUBLE_ACESSING_CONTENT'

function getPath() {
  const { reason, subject, specification } = this.$route.query
  return [subject, reason, specification].filter(Boolean).join('.')
}

function defaults(modal) {
  if (!modal) return

  return defaultsDeep(modal, {
    shouldRender: true,
    description: this.$t('ticketModal.shared.description'),
    onClose: ({ origin }) => {
      this.closeModal()
      if (/^(close-button|backdrop)$/.test(origin)) {
        this.sendCloseModalEvent()
      }
    },
    items: modal.items.map(item => defaultsDeep(item, { is: 'a', shouldRender: true })),
    help_message: {
      icon: 'circleInfo',
      onClick: () => {
        this.closeModal()
        this.sendOpenRequestEvent()
      },
      label: this.$t('ticketModal.shared.helpMessage'),
    },
  })
}

export function getModal() {
  const modals = {
    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.UNSUBSCRIBE.slug}.${SPECIFICATION.CANCEL_SUBSCRIPTION.slug}`]:
      CANCEL_SUBSCRIPTION.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CERTIFICATE_EMISSION.slug}`]: CERTIFICATE_EMISSION.call(this),

    [`${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.CHANGE_IN_REGISTRATION_INFORMATION.slug}`]:
      CHANGE_IN_REGISTRATION_INFORMATION.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION.slug}.${SPECIFICATION.CHANGE_REQUEST_REASON.slug}`]:
      CHANGE_REQUEST_REASON.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PRODUCT_QUESTIONS.slug}`]: PRODUCT_QUESTIONS.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.REFUND_OR_CANCELLATION.slug}.${SPECIFICATION.REQUEST_REFUND.slug}`]:
      REQUEST_REFUND.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.REFUND_OR_CANCELLATION.slug}.${SPECIFICATION.TRACK_REFUND.slug}`]:
      TRACK_REFUND.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.TROUBLE_ACESSING_CONTENT.slug}`]:
      TROUBLE_ACESSING_CONTENT.call(this),

    [`${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.BUYER_PHYSICAL_PRODUCTS.slug}.${SPECIFICATION.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS.slug}`]:
      PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS.call(this),

    [`${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug}`]:
      CHANGE_DOCUMENT_CNPJ_TO_CNPJ.call(this),

    [`${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_REGISTRATION.slug}`]:
      CHANGE_REGISTRATION.call(this),
    [`${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug}`]:
      CHANGE_DOCUMENT_CNPJ_TO_CPF.call(this),

    [`${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug}`]:
      CHANGE_DOCUMENT_CNPJ_TO_CNPJ.call(this),
    [`${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug}.${SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug}`]:
      CHANGE_DOCUMENT_CNPJ_TO_CPF.call(this),
    [`${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug}.${SPECIFICATION.CHANGE_REGISTRATION.slug}`]:
      CHANGE_REGISTRATION.call(this),
  }

  return defaults.call(this, modals[getPath.call(this)])
}
