import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=e85c1c1c&scoped=true"
import script from "./Button.vue?vue&type=script&setup=true&lang=js"
export * from "./Button.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Button.vue?vue&type=style&index=0&id=e85c1c1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e85c1c1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
