export default {
  name: 'Form',
  props: {
    id: {
      type: String,
      default: '',
    },
    novalidate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    submit: function (event) {
      const customValidation = this.isValidForm(this.$children)
      const action = customValidation ? 'submit' : 'error'
      this.$emit(action, event)
    },
    reset: function (event) {
      this.$children.some(vueComponent => {
        if (vueComponent.reset) {
          vueComponent.reset()
        }
      })
      this.$emit('reset', event)
    },
    isValidForm: function (formChildren) {
      const invalidForm = formChildren.some(vueComponent => {
        if (vueComponent.invalid || vueComponent.invalidRequired) {
          if (vueComponent.focus) {
            vueComponent.focus()
          }

          return true
        }
      })

      return !invalidForm
    },
  },
}
