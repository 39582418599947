import { computed, ref, useContext, useStore } from '@nuxtjs/composition-api'
import { useLocalStorageRef, useOpenTicketFlow } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step2-body',
  setup(__props, { expose }) {

const { $t } = useContext()
const { inTheFlow } = useOpenTicketFlow()
const store = useStore()

const inputRef = ref(null)
const value = useLocalStorageRef('formRequest.body', '')

const shouldRenderAISummary = computed(() => store.getters['ticketFormAISummary/shouldRenderAISummary'])

const label = computed(() => {
  if (shouldRenderAISummary.value) {
    return $t(`ticket.message.fields.body.ai.title`)
  }

  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [
        SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug,
        SPECIFICATION.CHANGE_REGISTRATION.slug,
        SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug,
      ],
    })
  ) {
    return $t(`ticket.message.fields.body.registration_and_modification_of_personal_data.title`)
  }

  return $t(`ticket.message.fields.body.default.title`)
})

const placeholder = computed(() => {
  if (shouldRenderAISummary.value) {
    return $t(`ticket.message.fields.body.ai.placeholder`)
  }

  if (
    inTheFlow({
      subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
      reason: [
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER.slug,
        SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE.slug,
      ],
      specification: [
        SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ.slug,
        SPECIFICATION.CHANGE_REGISTRATION.slug,
        SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF.slug,
      ],
    })
  ) {
    return $t(`ticket.message.fields.body.registration_and_modification_of_personal_data.placeholder`)
  }

  return $t(`ticket.message.fields.body.default.placeholder`)
})

function updateFormData(formData) {
  if (value.value) formData.set('body', value.value)
}

expose({
  invalid: computed(() => inputRef.value?.invalid),
  required: computed(() => inputRef.value?.required),
  focus: () => inputRef.value?.focus(),
  updateFormData,
  value,
})

return { __sfc: true,$t, inTheFlow, store, inputRef, value, shouldRenderAISummary, label, placeholder, updateFormData }
}

}