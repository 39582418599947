import { computed, ref } from 'vue'


export default {
  __name: 'TagGroup',
  props: {
  value: {
    type: [String, Array],
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  options: {
    type: Array,
    default: () => [],
  },
  required: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: undefined,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
},
  emits: ['change'],
  setup(__props, { expose, emit }) {

const props = __props;



const inputTag = ref(null)
const isFocused = ref(false)
const selected = ref([props.value].flat().filter(Boolean))



const invalid = computed(() => props.required && !selected.value.length)

function focus() {
  inputTag.value?.querySelector('input[type=checkbox]')?.focus()
  isFocused.value = true
}

function handleChange({ target: { value, checked } }) {
  if (!props.multiple) {
    selected.value = []
  }
  if (checked) {
    selected.value.push(value)
  } else {
    selected.value = selected.value.filter(v => v !== value)
  }
  emit('change', props.multiple ? selected.value : selected.value[0])
}

expose({ focus, invalid })

return { __sfc: true,props, inputTag, isFocused, selected, emit, invalid, focus, handleChange }
}

}