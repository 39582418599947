var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'step-navigation',
    _vm.isCenterContainer ? 'center-container' : '',
    _vm.isLastStep ? 'newPositionButtonCancel' : '',
  ]},_vm._l((_vm.actions.filter(action => !action.hidden)),function({ disabled, leftIcon, rightIcon, onClick, label, className }){return _c('button',{key:label,class:[
      'hot-button hot-button--primary',
      disabled ? 'hot-button--disabled' : !className && 'hot-button--active',
      className,
    ],attrs:{"disabled":disabled},on:{"click":function($event){return onClick()}}},[(Boolean(leftIcon))?_c('hc-icon',{attrs:{"name":leftIcon}}):_vm._e(),_vm._v("\n    "+_vm._s(_vm.$t(label))+"\n    "),(Boolean(rightIcon))?_c('hc-icon',{attrs:{"name":rightIcon}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }