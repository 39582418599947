import { TOGGLE } from '~/enum/toggles'
import { isLoggedAndIsBuyer } from '~/enum/utils'

export default function () {
  const articleId = this.$config.changeRequestReasonArticleId
  const checkoutCampaign = this.$route.query.itm_campaign?.endsWith(
    '_activation_first-access_hiperlink_change-purchase-email'
  )
  const isBlipChat = this.$store.getters['chatbot/isBlipChat']

  return {
    shouldRender:
      /^(pt-br|en|es)$/.test(this.$i18n.locale) &&
      !this.isVisitedArticle(articleId) &&
      !checkoutCampaign &&
      isLoggedAndIsBuyer(this.$checkLogged(), isBlipChat),
    title: this.$t('ticketModal.changeRequestReason.title'),
    description: this.$t('ticketModal.changeRequestReason.description'),
    toggle: TOGGLE.CHATBOT_CHANGE_EMAIL,
    items: [
      {
        to: 'javascript:void(0)',
        onClick: this.openChat,
        label: this.$t('ticketModal.changeRequestReason.links.chatbot-help'),
        dataLayer: {
          event_details: 'Click::Chatbot',
        },
      },
    ],
    help_message: {
      icon: null,
      label: this.$t('ticketModal.changeRequestReason.helpMessage', {
        link: `${this.$i18n.locale}/article/${articleId}?&itm_medium=internal&itm_source=help-center&itm_campaign=new-request_modal_change_email`,
      }),
      onClick: () => {}, // This is a noop function
      dataLayer: {
        event_details: 'Click::FAQ',
      },
    },
    dataLayer: {
      custom_event_name: 'show_modal_change_purchase_email',
      event_details: 'Open::ModalChangePurchaseEmail',
    },
  }
}
