import { computed, ref, useContext } from '@nuxtjs/composition-api'
import { useOpenTicketFlow } from '~/composables'
import { SLUG } from '~/enum/form/constants'
import { SUBJECT } from '~/enum/form/subject'


export default {
  __name: 'Step3-address',
  setup(__props, { expose }) {

const { $t } = useContext()
const { inTheFlow } = useOpenTicketFlow()

const inputRef = ref(null)
const value = ref('')

const shouldRender = computed(() => {
  return inTheFlow({
    subject: [SLUG.IM_A_PRODUCER_AND_I_NEED_HELP, SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP],
    reason: [SUBJECT.HOTMART_RECOGNITIONS_PRODUCER.slug, SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE.slug],
  })
})

const label = computed(() => $t('ticket.complementaryData.fields.address.title'))

const placeholder = computed(() => $t('ticket.complementaryData.fields.address.placeholder'))

function updateFormData(formData) {
  if (value.value) formData.set('addressStreet', value.value)
}

expose({
  invalid: computed(() => inputRef.value?.invalid),
  required: computed(() => inputRef.value?.required),
  focus: () => inputRef.value?.focus(),
  updateFormData,
  value,
})

return { __sfc: true,$t, inTheFlow, inputRef, value, shouldRender, label, placeholder, updateFormData }
}

}