import { render, staticRenderFns } from "./TicketReason.vue?vue&type=template&id=eff955b4&scoped=true"
import script from "./TicketReason.js?vue&type=script&lang=js&external"
export * from "./TicketReason.js?vue&type=script&lang=js&external"
import style0 from "./TicketReason.scss?vue&type=style&index=0&id=eff955b4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff955b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default})
