import { render, staticRenderFns } from "./StepNavigation.vue?vue&type=template&id=0bfc2e77&scoped=true"
import script from "./StepNavigation.js?vue&type=script&lang=js&external"
export * from "./StepNavigation.js?vue&type=script&lang=js&external"
import style0 from "./StepNavigation.scss?vue&type=style&index=0&id=0bfc2e77&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bfc2e77",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
