export default function () {
  return {
    shouldRender: !this.shouldRenderAISummary,
    title: this.$t('acessProblems.modal.title'),
    description: this.$t('acessProblems.modal.description'),
    items: [
      {
        to: `https://hotm.art/e0GIW8`,
        target: '_blank',
        label: this.$t('acessProblems.modal.access'),
      },
      {
        to: 'https://hotm.art/B8DMZ44U',
        target: '_blank',
        label: this.$t('acessProblems.modal.problem'),
      },
      {
        to: 'https://hotm.art/ah2AkBRs',
        target: '_blank',
        label: this.$t('acessProblems.modal.first-access'),
      },
      {
        to: 'https://hotm.art/8V4JyNYY',
        target: '_blank',
        label: this.$t('acessProblems.modal.app'),
      },
    ],
  }
}
