export default function () {
  return {
    title: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.title'),
    description: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.description'),
    onClose: this.closeModal,
    items: [
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg01', {
          link: 'https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-tributaria/cadastros/cnpj/juntas-comerciais-conveniadas-para-inscricao-e-alteracao-do-cnpj/relacao-das-juntas-comerciais-conveniadas',
        }),
      },
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg02'),
      },
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg03'),
      },
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg04'),
      },
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg05'),
      },
      {
        is: 'li',
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.msg06'),
      },
    ],
    help_message: {
      icon: null,
      label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.faq', {
        link: `https://help.hotmart.com/${this.$i18n.locale}/article/360054389632`,
      }),
      onClick: () => {},
    },
    actions: [
      {
        label: this.$t('ticketModal.modificationPersonalDataProducerCNPJtoCPF.modal.action'),
        onClick: this.closeModal,
      },
    ],
  }
}
