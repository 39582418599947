import mediaQuery from '~/mixins/media-query'
import Fuzzy from '~/utils/fuzzy'

export default {
  name: 'Select',
  mixins: [mediaQuery],
  props: {
    id: String,
    name: String,
    noResultText: String,
    label: String,
    disabled: Boolean,
    required: Boolean,
    loading: Boolean,
    value: [Number, String],
    options: Array,
    onChange: Function,
    placeholder: {
      type: String,
      default: '',
    },
    mobileSearchTitle: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      isOpen: false,
      isFocused: false,
      alreadyFocused: false,
      mobileSearch: '',
    }
  },
  mounted() {
    if (this.value) {
      const selectedOption = this.options?.find(element => element.id.toString() === this.value.toString())
      if (selectedOption) this.$refs.select.shadowRoot.querySelector('input').value = selectedOption.text
    }
  },
  computed: {
    formattedMobileOptions: function () {
      if (!this.mobileSearch) return this.options

      return Fuzzy.filter(this.mobileSearch, this.options, {
        pre: '<b>',
        post: '</b>',
        extract: ({ text }) => text,
      }).map(el => ({
        ...el.original,
        text: el.string,
      }))
    },
    invalid: function () {
      return this.userInteract ? this.invalidRequired : false
    },
    invalidRequired: function () {
      const invalid = this.required && !this.value

      return invalid ? 'formValidation.required' : false
    },
    userInteract: function () {
      return this.submitted || this.alreadyFocused
    },
    styleInvalidInput: function () {
      return this.invalid && this.userInteract ? 'is-invalid' : ''
    },
  },
  watch: {
    // Workaround to clear select
    value: function (value) {
      if (!value) this.$refs.select._input.value = ''
    },
  },

  methods: {
    onChangeHandler(option = {}) {
      if (this.onChange) {
        this.onChange(option.detail.value)
      }
      this.$emit('input', option.detail ? option.detail.value : '')
    },
    onChangeMobileHandler(id) {
      this.onChangeHandler({
        detail: {
          value: id,
        },
      })
      this.$refs.mobileModalRef?.removeAttribute('open')
    },
    onOpenHandler() {
      this.isOpen = true
      this.$refs.mobileModalRef?.setAttribute('open', true)
      if (this.isMobile) document.activeElement.blur()
    },
    onCloseHandler() {
      this.isOpen = false
    },
    onFocus() {
      this.isFocused = true
      this.alreadyFocused = true
    },
    blurFocused: function () {
      this.alreadyFocused = false
    },
    focus: function () {
      this.$refs.select.shadowRoot.querySelector('input').focus()
      this.alreadyFocused = true
      this.isFocused = true
    },
    reset: function () {
      this.isOpen = false
      this.isFocused = false
      this.alreadyFocused = false
      this.$emit('input', '')
    },
  },
}
