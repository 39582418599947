import { REFUND_TAG } from './constants'

export const REFUND = [
  {
    key: 'accessData',
    tag: REFUND_TAG.ACCESS_DATA,
    slug: 'access-data',
  },
  {
    key: 'highCosts',
    tag: REFUND_TAG.HIGH_COSTS,
    slug: 'high-costs',
  },
  {
    key: 'fileProblems',
    tag: REFUND_TAG.FILE_PROBLEMS,
    slug: 'file-problems',
  },
  {
    key: 'wrongPassword',
    tag: REFUND_TAG.WRONG_PASSWORD,
    slug: 'wrong-password',
  },
  {
    key: 'contactProducer',
    tag: REFUND_TAG.CONTACT_PRODUCER,
    slug: 'contact-producer',
  },
  {
    key: 'productQuality',
    tag: REFUND_TAG.PRODUCT_QUALITY,
    slug: 'product-quality',
  },
  {
    key: 'productIncomplete',
    tag: REFUND_TAG.PRODUCT_INCOMPLETE,
    slug: 'product-incomplete',
  },
  {
    key: 'cantContinue',
    tag: REFUND_TAG.CANT_CONTINUE,
    slug: 'cant-continue',
  },
  {
    key: 'duplicatePurchase',
    tag: REFUND_TAG.DUPLICATE_PURCHASE,
    slug: 'duplicate-purchase',
  },
]
