export const fieldValues = [
  'birthDate',
  'productEmail',
  'toolName',
  'salesPageLink',
  'profile',
  'hotlink',
  'operationalSystem',
  'howDidYouKnow',
  'productId',
  'sourceProductId',
  'importReason',
  'productName',
  'eventTicketCode',
  'automatedDecisionReview',
  'bonusContent',
  'device',
  'updateEmailToAllPurchases',
  'updateEmailInMyHotmartRegistration',
  'changeRequestReason',
  'country',
  'countryPrivacy',
  'region',
  'coProducerName',
  'coProducerEmail',
  'addressStreetCode',
  'addressNeighborhood',
  'addressCity',
  'addressState',
  'addressCountry',
  'braceletsNumber',
  'denouncedProductPageLink',
  'piracyTextExample',
  'notificationReceivedDetail',
  'currentDocument',
  'newCorrectionDocument',
  'newUpdateDocument',
  'destinationCountry',
  'tool',
  'testPurchase',
  'pixelId',
  'pixelEmail',
  'communityId',
  'credentialsType',
  'acquisitionURL',
  'studentEmail',
  'integrationEmail',
  'accessType',
  'leadCollect',
  'shortLink',
  'automaticEmailProvider',
  'materialLink',
  'leadPageLink',
  'endDate',
  'startDate',
  'startOriginalDate',
  'endOriginalDate',
  'startNewDate',
  'endNewDate',
  'productLink',
  'checkoutLink',
  'paymentMethod',
  'purchaseDate',
  'subscriptionCode',
  'billingAmount',
  'billingDate',
  'creditCardFirstSixDigits',
  'creditCardLastFourDigits',
  'awardsAchieved',
  'phoneNumber',
  'fullAddress',
  'deliveryCountry',
]

export const endpoints = {
  BUYER_FORM: '/buyerForm',
  PRODUCER_OR_CO_PRODUCER_FORM: '/producerOrCoProducerForm',
  AFFILIATE_FORM: '/affiliateForm',
  NEW_USER_FORM: '/newUserForm',
  SPARKLE_FORM: '/sparkleForm',
  EXTENSIONS_FORM: '/extensionsForm',
  DATA_PRIVACY_FORM: '/dataPrivacyForm',
}
